import React, { Component } from 'react';
import { createAppointment, updateAppointment, deleteAppointment, getAppointment, splitAppointment } from '../../logic/calendar';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row, Grid, FormGroup, FormControl, ControlLabel, Checkbox, ButtonToolbar, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import access from 'safe-access';
import map from './../../assets/images/map.png';
import moment from 'moment';
import { camel } from './../../libs/general';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import {countryCodeToRegionCodeMap} from './../../config';

class AppointmentDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {
				fromTimestamp: '',
				toTimestamp: '',
				type: 'available',
				patient: {
					name: {
						firstName: '',
						lastName: ''
					},
					email: '',
					mobile: {
						countryPrefixCode: '',
						mobileNumber: ''
					},
					landline: {
						countryPrefixCode: '',
						landlineNumber: ''
					},
					idCard: '',
					dob: '',
					remindManually: "true"
				},
				clinic: '',
				pharmacyRemarks: '',
				doctorRemarks: '',
				smsReminder: 'NEVER SENT',
				emailReminder: 'NEVER SENT',
				billing: {
					doctorRate: '',
					clinicFee: '',
					toBePaidAt: '',
					services: []
				}
			},
			editMode: false,
			remarksAmount: 0,
			billingAmount: 0,
			currentView: 'general',
			fields: '',
			error: ''
		};
		this.handleDelete = this.handleDelete.bind(this);
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleCreate = this.handleCreate.bind(this);
	}
	componentWillMount = async () => {
		let fromTimestamp = '', toTimestamp = '';

		if (access(this, 'props.location.state.refreshRequired') === true) {
			const appointment = await getAppointment(window.localStorage.getItem('currentDoctorId'), this.props.location.state.item.creationTimestamp);
			this.props.location.state.item = appointment;
		}

		if (access(this, 'props.location.state.item.type') !== 'pending' && access(this, 'props.location.state.item.fromTimestamp')) {
			fromTimestamp = this.props.location.state.item.fromTimestamp;
			window.localStorage.setItem('tempFromTimestamp', fromTimestamp);
		} else {
			fromTimestamp = window.localStorage.getItem('tempFromTimestamp');
			window.localStorage.removeItem('tempFromTimestamp');
		}

		if (access(this, 'props.location.state.item.type') !== 'pending' && access(this, 'props.location.state.item.toTimestamp')) {
			toTimestamp = this.props.location.state.item.toTimestamp;
			window.localStorage.setItem('tempToTimestamp', toTimestamp);
		} else {
			toTimestamp = window.localStorage.getItem('tempToTimestamp');
			window.localStorage.removeItem('tempToTimestamp');
		}

		if (access(this, 'props.location.state.item.type')) this.props.location.state.item.type = "filled";

		if (this.props && this.props.location && this.props.location.state && this.props.location.state.item && this.props.location.state.item.patient) {
			const { item } = this.props.location.state;
			item.fromTimestamp = fromTimestamp;
			item.toTimestamp = toTimestamp;

			let remarksAmount = 0;
			let billingAmount = 0;
			if (item && item.reminders) {
				if (item.reminders.email) {
					switch (item.reminders.email.lastStatus) {
						case "SENT":
							item.emailReminder = 'SENT';
							if (item.reminders.email.lastSentTimestamp)
								item.emailReminder += ` on ${moment(item.reminders.email.lastSentTimestamp).format('DD MMM YYYY HH:mm')}`;
							break;
						case "ERROR":
							item.emailReminder = 'ERROR OCCURED WHEN SENDING';
							break;
						default:
							item.emailReminder = 'NEVER SENT';
					}
				} else item.emailReminder = 'NEVER SENT';
				if (item.reminders.sms) {
					switch (item.reminders.sms.lastStatus) {
						case "SENT":
							item.smsReminder = 'SENT';
							if (item.reminders.sms.lastSentTimestamp)
								item.smsReminder += ` on ${moment(item.reminders.sms.lastSentTimestamp).format('DD MMM YYYY HH:mm')}`;
							break;
						case "ERROR":
							item.smsReminder = 'ERROR OCCURED WHEN SENDING';
							break;
						default:
							item.smsReminder = 'NEVER SENT';
					}
				} else item.smsReminder = 'NEVER SENT';
			} else {
				item.emailReminder = 'NEVER SENT';
				item.smsReminder = 'NEVER SENT';
			}
			if (item && item.pharmacyRemarks && item.pharmacyRemarks.length > 0) remarksAmount++;
			if (item && item.doctorRemarks && item.doctorRemarks.length > 0) remarksAmount++;
			if (item && item.billing && item.billing.services && item.billing.services.length > 0)
				billingAmount = item.billing.services.filter((service) => service.bought === true).length;

			let fields = null;
			try {
				fields = JSON.parse(window.localStorage.getItem('currentDoctorFields'));
			} catch (e) {
				fields = null;
			}
			this.setState({ item, editMode: true, remarksAmount, billingAmount, fields });
		} else {
			const state = this.state;
			state.item.fromTimestamp = fromTimestamp;
			state.item.toTimestamp = toTimestamp;
			state.item.clinic = window.localStorage.getItem('currentDoctorClinic');
			state.item.billing.doctorRate = window.localStorage.getItem('currentDoctorRate');
			state.item.billing.clinicFee = window.localStorage.getItem('currentDoctorClinicFee');
			state.item.billing.toBePaidAt = window.localStorage.getItem('currentDoctorPayAt');

			try {
				state.item.billing.services = JSON.parse(window.localStorage.getItem('currentDoctorServices'));
				state.fields = JSON.parse(window.localStorage.getItem('currentDoctorFields'));
			} catch (e) {
				state.item.billing.services = [];
				state.fields = null;
			}

			this.setState({ ...state });
		}
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	handleChangeView = (currentView) => this.setState({ currentView })
	handleSplit = async (e) => {
		e.preventDefault();

		if (window.confirm('Are you sure you want to split this appointment in half?')) {
			const app = await splitAppointment(window.localStorage.getItem('currentDoctorId'), this.state.item.creationTimestamp);

			if (app instanceof Error)
				this.handleError(app);
			else
				this.props.history.push("/");
		}
	}
	handleDelete = async (e) => {
		e.preventDefault();

		if (window.confirm('Are you sure you want to delete this appointment?')) {
			const app = await deleteAppointment(window.localStorage.getItem('currentDoctorId'), this.state.item.creationTimestamp);

			if (app instanceof Error)
				this.handleError(app);
			else
				this.props.history.push("/");
		}
	}
	handleCreate = async (e) => {
		e.preventDefault();

		const app = await createAppointment(window.localStorage.getItem('currentDoctorId'), this.state.item);

		if (app instanceof Error)
			this.handleError(app);
		else
			this.props.history.push("/");
	}
	handleUpdate = async (e) => {
		e.preventDefault();

		const app = await updateAppointment(window.localStorage.getItem('currentDoctorId'), this.state.item.creationTimestamp, this.state.item);

		if (app instanceof Error)
			this.handleError(app);
		else
			this.props.history.push("/");
	}
	handleChange = (e, i) => {
		if (e && e.target && e.target.id) {
			const field = e.target.id;
			const value = e.target.value;

			const item = this.state.item;
			item.billing.services[i][field] = value;
			this.setState({ item });
		} else return;
	}
	handleCheckChange = (e, i) => {
		if (e && e.target && e.target.id && e.target.value !== null) {
			const field = e.target.id;
			const value = e.target.checked;

			const item = this.state.item;
			item.billing.services[i][field] = value;
			this.setState({ item });
		} else return;
	}
	isDisabled = (name) => {
		const fields = this.state.fields;
		if (fields) {
			const field = fields.find((f) => f.fieldName === name);
			if (field && (field.visible === true || field.visible === "true")) return false;
			else return true;
		}

		return false;
	}
	isRequired = (name) => {
		const fields = this.state.fields;
		if (fields) {
			const field = fields.find((f) => f.fieldName === name);
			if (field && (field.required === true || field.required === "true")) {
				if (field.visible === false || field.visible === "false") return false;
				else return true;
			}
			else return false;
		}

		return false;
	}
	handleEmailChange = (e) => {
		const remindManually = (e.target.value || access(this, 'state.item.patient.mobile.mobileNumber')) ? "false" : "true";
		this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, email: e.target.value, remindManually } } });
	}
	handleMobileChange = (a,mob,obj) => {
		const _self = this;
		const remindManually = (mob || access(_self, 'state.item.patient.email')) ? "false" : "true";

		let parsedMob = "";
		if (mob) parsedMob = parseInt(mob,10);
		_self.setState({ item: { ..._self.state.item, patient: { ..._self.state.item.patient, remindManually, mobile: { ..._self.state.item.patient.mobile, mobileNumber: parsedMob } } } });

	}
	handleCountryMobileChange = (a,obj)=> {
		const _self = this;
		if (obj && obj.dialCode){
			_self.setState({ item: { ..._self.state.item, patient: { ..._self.state.item.patient, mobile: { ..._self.state.item.patient.mobile, countryPrefixCode: parseInt(obj.dialCode,10) } } } });
		}
	}
	getCountryCode = () => {
		if (this.state.item.patient.mobile.countryPrefixCode){
			return countryCodeToRegionCodeMap[this.state.item.patient.mobile.countryPrefixCode][0].toLowerCase();
		}

		return "mt";
	}
	render() {
		let { editMode } = this.state;
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/">Back</Link>
				</div>
				<div className="tiny-push"></div>
				<div style={{ marginBottom: 0, paddingBottom: 0 }} className="pages__modal--container">
					<nav className="appointment__nav--container">
						<button onClick={() => this.handleChangeView('general')} id="general-info-button">GENERAL INFO</button>
						<button onClick={() => this.handleChangeView('remarks')}>REMARKS
						{
								this.state.remarksAmount !== 0 ?
									<p style={this.state.billingAmount ? { display: false } : { display: 'none' }} >{this.state.remarksAmount ? this.state.remarksAmount : undefined}</p>
									: undefined
							}
						</button>
						<button onClick={() => this.handleChangeView('billing')}>BILLING
							{
								this.state.remarksAmount !== 0 ?
									<p style={this.state.billingAmount ? { display: false } : { display: 'none' }} > {this.state.billingAmount ? this.state.billingAmount : undefined}</p>
									: undefined
							}
						</button>
					</nav>
					<div className={editMode === true ? "small-push" : "tiny-push"}></div>
					<form autoComplete='on' onSubmit={editMode === true ? this.handleUpdate : this.handleCreate}>
						{
							this.state.currentView === 'general' ?
								<Grid>
									<Row>
										<Col xsOffset={2} smOffset={2} mdOffset={2} lgOffset={2} xs={5} sm={5} md={5} lg={5}>
											{
												this.state.editMode === false && (window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "limitedadmin" || window.localStorage.getItem("userRights") === "doctor") ?
													<Link className="appointment__pending-button--container" to={{
														pathname: `/appointments/${window.localStorage.getItem('currentDoctorId')}/pending`
													}}>Import details from pending list</Link>
													: undefined
											}
										</Col>
									</Row>
									<Row>
										<Col xs={12} sm={8} md={8} lg={8}>{/*Patient Details*/}
											<Row>
												<Col xsOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='firstName'>First Name<span style={{ display: this.isRequired("firstname") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='firstName'
															defaultValue={access(this, "state.item.patient.name.firstName") ? this.state.item.patient.name.firstName : undefined}
															required={this.isRequired("firstname")}
															disabled={this.isDisabled("firstname")}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, name: { ...this.state.item.patient.name, firstName: e.target.value } } } })}
															type='text'>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='lastName'>Surname<span style={{ display: this.isRequired("lastname") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='lastName'
															defaultValue={access(this, "state.item.patient.name.lastName") ? this.state.item.patient.name.lastName : undefined}
															required={this.isRequired("lastname")}
															disabled={this.isDisabled("lastname")}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, name: { ...this.state.item.patient.name, lastName: e.target.value } } } })}
															type='text'>
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col xsOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='idCard'>ID Card<span style={{ display: this.isRequired("idcard") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='idCard'
															defaultValue={access(this, "state.item.patient.idCard") ? this.state.item.patient.idCard : undefined}
															required={this.isRequired("idcard")}
															disabled={this.isDisabled("idcard")}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, idCard: e.target.value } } })}
															type='text'>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='mobileNumber'>Mobile Number<span style={{ display: this.isRequired("mobilenumber") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<IntlTelInput
															id='mobileNumber'
															containerClassName="intl-tel-input"
															inputClassName="form-control"
															separateDialCode={true}
															defaultCountry={this.getCountryCode()}
															value={access(this, "state.item.patient.mobile.mobileNumber") ? this.state.item.patient.mobile.mobileNumber.toString() : ""}
															preferredCountries={["mt","it","gb"]}
															telInputProps={{required:this.isRequired("mobilenumber")}}
															disabled={this.isDisabled("mobilenumber")}
															autoComplete="off"
															onPhoneNumberChange={this.handleMobileChange}
															onSelectFlag={this.handleCountryMobileChange}
														/>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col  xsOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='email'>Email<span style={{ display: this.isRequired("email") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='email'
															defaultValue={access(this, "state.item.patient.email") ? this.state.item.patient.email : undefined}
															required={this.isRequired("email")}
															disabled={this.isDisabled("email")}
															onChange={this.handleEmailChange}
															type='email'>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='landlineNumber'>Landline Number<span style={{ display: this.isRequired("landlinenumber") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='landlineNumber'
															autoComplete="asdfasdfweqr"  // chrome bug --> needs to be a random string for now
															defaultValue={access(this, "state.item.patient.landline.landlineNumber") ? this.state.item.patient.landline.landlineNumber : undefined}
															required={this.isRequired("landlinenumber")}
															disabled={this.isDisabled("landlinenumber")}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, landline: { ...this.state.item.patient.landline, landlineNumber: e.target.value } } } })}
															type='tel'>
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col  xsOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='dob'>Date of Birth<span style={{ display: this.isRequired("dob") ? 'inline' : 'none' }}>&nbsp;*</span></ControlLabel>
														<FormControl
															id='dob'
															defaultValue={access(this, "state.item.patient.dob") ? this.state.item.patient.dob : undefined}
															required={this.isRequired("dob")}
															disabled={this.isDisabled("dob")}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, dob: e.target.value } } })}
															type='text'>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={5} md={5} lg={5}>
													<FormGroup>
														<ControlLabel htmlFor='remindManually'>Remind Patient Manually</ControlLabel>
														<FormControl
															componentClass="select"
															id='remindManually'
															value={access(this, "state.item.patient.remindManually") ? this.state.item.patient.remindManually : undefined}
															onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, remindManually: e.target.value } } })}
														>
															<option value="false" label="No"></option>
															<option value="true" label="Yes"></option>
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
											<hr></hr>
											<Row>
												<Col  xsOffset={1} xs={12} sm={7} md={7} lg={7}>
													<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Medicaid+Pharmacy/@35.8488505,14.5055409,17z">
														<img className="appointment__map--img" src={map} alt="Medicaid Pharmacy location on Google Maps" />
													</a>
												</Col>
												<Col className="appointment__address--container"  xsOffset={1}  xs={12} sm={5} md={5} lg={5}>
													<p id="business-name">Medicaid Pharmacy</p>
													<p id="business--address">62, Vjal it-Torri</p>
													<p id="business-town">Gudja</p>
													<a id="business-website" target="_blank" rel="noopener noreferrer" href="http://www.medicaidclinics.com">www.medicaidclinics.com</a><br />
													<a id="business-email" target="_blank" rel="noopener noreferrer" href="mailto:medicaidpharmacy@gmail.com">medicaidpharmacy@gmail.com</a>
													<p id="business-telephone">+356 21676294</p>
												</Col>
											</Row>
										</Col>

										<Col xs={12} sm={4} md={4} lg={4}>{/*Doc & Appointment Details*/}
											<div className="appointment__doctor-appointment--container" >
												<Row><p className="appointment__doctor--name">
													{`${camel(window.localStorage.getItem('currentDoctorTitle'))}. ${camel(window.localStorage.getItem('currentDoctorLastName'))}, ${camel(window.localStorage.getItem('currentDoctorFirstName'))}`}
												</p></Row>
												<Row><p className="appointment__doctor--speciality">{camel(window.localStorage.getItem('currentDoctorSpeciality'))}</p></Row>
												<div className="small-push"></div>
												<Row>
													<Col xs={6} sm={6} md={6} lg={6}>
														<ControlLabel htmlFor='fromTimestamp'>From</ControlLabel>
														<div>
															<p id="fromTimestampDate">{moment(this.state.item.fromTimestamp).format('DD MMM YYYY')}</p>
															<p id="fromTimestampTime">{moment(this.state.item.fromTimestamp).format('HH:mm')}</p>
														</div>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6}>
														<ControlLabel htmlFor='toTimestamp'>To</ControlLabel>
														<div>
															<p id="toTimestampDate">{moment(this.state.item.toTimestamp).format('DD MMM YYYY')}</p>
															<p id="toTimestampTime">{moment(this.state.item.toTimestamp).format('HH:mm')}</p>
														</div>
													</Col>
												</Row>
												<div className="tiny-push"></div>
												<Row>
													<Col xs={6} sm={6} md={6} lg={6}>
														<FormGroup>
															<ControlLabel htmlFor="clinic">Clinic</ControlLabel>
															<FormControl
																componentClass="select"
																id='clinic'
																required
																value={this.state.item.clinic}
																onChange={(e) => this.setState({ item: { ...this.state.item, clinic: e.target.value } })}>
																<option value=''>--</option>
																<option value='Clinic 1'>Clinic 1</option>
																<option value='Clinic 2'>Clinic 2</option>
																<option value='Clinic 3'>Clinic 3</option>
																<option value='Clinic 4'>Clinic 4</option>
															</FormControl>
														</FormGroup>
													</Col>
													<Col xs={6} sm={6} md={6} lg={6}>
													</Col>
												</Row>
												<Row><p className="appointment__appointment--time-notice">* Time is approximate</p></Row>
												{
													(window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "limitedadmin" || window.localStorage.getItem("userRights") === "doctor") && this.state.item.type !== 'filled' ?
														<Row>
															<Col xs={12} sm={12} md={12} lg={12}>
																<ButtonToolbar className="appointment__pending--buttons">
																	<ToggleButtonGroup type="radio" name="pending" defaultValue='available' value={this.state.item.type} onChange={(val) => this.setState({ item: { ...this.state.item, type: val } })}>
																		<ToggleButton value='available'>Not Pending</ToggleButton>
																		<ToggleButton value='both'>Both</ToggleButton>
																		<ToggleButton value='pending'>Pending</ToggleButton>
																	</ToggleButtonGroup>
																</ButtonToolbar>
															</Col>
														</Row>
														: null
												}
											</div>
										</Col>
									</Row>
								</Grid>
								: this.state.currentView === 'remarks' ?
									<Grid>
										<Col xs={12} sm={12} md={12} lg={12}>
											<FormGroup>
												<ControlLabel htmlFor='pharmacyRemarks'>Pharmacy's Remarks</ControlLabel>
												<FormControl
													id='pharmacyRemarks'
													defaultValue={access(this, "state.item.pharmacyRemarks") ? this.state.item.pharmacyRemarks : undefined}
													onChange={(e) => this.setState({ item: { ...this.state.item, pharmacyRemarks: e.target.value } })}
													componentClass="textarea"
													rows="6">
												</FormControl>
											</FormGroup>
											<FormGroup>
												<ControlLabel htmlFor='doctorRemarks'>Doctor's Remarks</ControlLabel>
												<FormControl
													id='doctorRemarks'
													defaultValue={access(this, "state.item.doctorRemarks") ? this.state.item.doctorRemarks : undefined}
													onChange={(e) => this.setState({ item: { ...this.state.item, doctorRemarks: e.target.value } })}
													componentClass="textarea"
													rows="6">
												</FormControl>
											</FormGroup>
											<div className="appointment__reminders--container">
												<label>Email Reminder:&nbsp;<span>{this.state.item.emailReminder}</span></label>
												<br /><label>SMS Reminder:&nbsp;<span>{this.state.item.smsReminder}</span></label>
											</div>
										</Col>
									</Grid>
									:
									<Grid>
										<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={12} md={4} lg={4}
											className="billing-settings__services--col">
											<h3 className="centered-text">Services</h3>
											{
												access(this, "state.item.billing.services") ?
													this.state.item.billing.services.map((service, i) => {
														return (
															<fieldset className="billing-settings__form--item"
																style={
																	this.state.item.billing.services.length > 1 ? undefined : { border: "none" }
																}
																key={i}>
																<p className="appointment__service--name">{service.title}</p>
																<p className="appointment__service--price">€&nbsp;{service.price}</p>
																<Checkbox
																	id="bought"
																	inline
																	checked={service.bought}
																	onChange={(e) => this.handleCheckChange(e, i)}>Purchased?</Checkbox>
															</fieldset>
														)
													})
													: undefined
											}
										</Col>
										<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={4} sm={4} md={4} lg={4}>
											<FormGroup>
												<ControlLabel htmlFor='doctorRate'>Doctor Rate</ControlLabel>
												<FormControl
													id='doctorRate'
													defaultValue={access(this, "state.item.billing.doctorRate") ? this.state.item.billing.doctorRate : undefined}
													required
													onChange={(e) => this.setState({ item: { ...this.state.item, billing: { ...this.state.item.billing, doctorRate: e.target.value } } })}
													type='number'>
												</FormControl>
											</FormGroup>
											<FormGroup>
												<ControlLabel htmlFor='clinicFee'>Clinic Fee</ControlLabel>
												<FormControl
													id='clinicFee'
													defaultValue={access(this, "state.item.billing.clinicFee") ? this.state.item.billing.clinicFee : undefined}
													required
													onChange={(e) => this.setState({ item: { ...this.state.item, billing: { ...this.state.item.billing, clinicFee: e.target.value } } })}
													type='number'>
												</FormControl>
											</FormGroup>
											<FormGroup>
												<ControlLabel htmlFor="toBePaidAt">To be paid at</ControlLabel>
												<FormControl
													componentClass="select"
													id='toBePaidAt'
													required
													defaultValue={this.state.item.billing.toBePaidAt}
													onChange={(e) => this.setState({ item: { ...this.state.item, billing: { ...this.state.item.billing, toBePaidAt: e.target.value } } })}>
													<option value="">--</option>
													<option value='pharmacycounter'>Pharmacy Counter</option>
													<option value='doctorinclinic'>Doctor In Clinic</option>
												</FormControl>
											</FormGroup>
										</Col>
									</Grid>
						}
						<Row>
							<div className="medium-push"></div>
							<div className="pages__form--error centered-text">
								{
									this.state.error && this.state.error instanceof Array ?
										this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
										: <p>{this.state.error.message}</p>
								}
							</div>
							{
								this.state.currentView === 'general' ?
									<div>
										<button
											id="save"
											className="save-button center-block medium-button"
											type='submit'> {editMode === true ? 'Update' : 'Create'}</button>
									</div>
									: false
							}
						</Row>
					</form>
				</div>
				<div style={{ marginTop: 0 }} className="pages__action-bar--container">
					{
						this.state.item.type === "filled" && (window.localStorage.getItem("userRights") === "admin") ?
							<div onClick={this.handleSplit} className="pages__action-bar--item appointment__action-bar--split">
								<FontAwesomeIcon icon="hand-scissors" />
							</div>
							: false
					}
					<div onClick={this.handleDelete} className="pages__action-bar--item appointment__action-bar--delete">
						<FontAwesomeIcon icon="trash-alt" />
					</div>
				</div>
				<div className="medium-push"></div>
			</div >
		);
	}
}

export default withRouter(AppointmentDetails);