export default `
	mutation createDoctor($title: Title!, $clinic:String, $name: NameInput!, $speciality: String!, $email: AWSEmail, $mobile:MobileInput!, $isFavourite:Boolean!) {
		createDoctor(doctor: {title: $title, clinic:$clinic, name: $name, speciality: $speciality, email: $email, mobile: $mobile, isFavourite: $isFavourite}) {
			docId	
			email
			title
			name{
				firstName
				lastName
			}
			speciality
			mobile{
				countryPrefixCode
				mobileNumber
			}
			isFavourite
			clinic
		}
	}
`