export default `
	query listDoctors{
			listDoctors{
				items{
					docId
					name{
						firstName
						lastName
					}
					mobile{
						countryPrefixCode
						mobileNumber
					}
					title
					speciality
					email
					isFavourite
					clinic
					preferences{
						schedule{
							type
							startDate
							startHour
							startMinute
							endHour
							endMinute
							minsPerAppointment
						}
						alerts{
							type
							medium
							daysPrior
							hour
							minute
						}
						fields{
							fieldName
							visible
							required
						}
						billing{
							doctorRate
							clinicFee
							toBePaidAt
							services{
								title
								price
								bought
								currency
							}
						}
					}
				}
			}
		}
	`