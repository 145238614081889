import React, { Component } from 'react';
import { updateDoctor } from '../../logic/settings';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, FormControl, ControlLabel, Row, Col, Grid } from 'react-bootstrap';
import { clearStorage } from './../../libs/general';

class DoctorAlerts extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doc: null,
			error: ''
		};
		this.handleSave = this.handleSave.bind(this);
	}
	componentWillMount() {
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.doc) {
			const { doc } = this.props.location.state;
			this.setState({ doc },
				() => {
					if (!(this.state.doc.preferences && this.state.doc.preferences.alerts
						&& this.state.doc.preferences.alerts instanceof Array
						&& this.state.doc.preferences.alerts.length >= 1)) {
						this.addAlert();
					}
				}
			);
		}
	}
	handleChange = (e, i) => {
		if (e && e.target && e.target.id && e.target.value) {
			const field = e.target.id;
			const value = e.target.value;

			const doc = this.state.doc;
			doc.preferences.alerts[i][field] = value;
			this.setState({ doc });
		} else return;
	}
	handleSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const doc = await updateDoctor(this.state.doc);
		clearStorage();

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	addAlert = () => {
		const doc = this.state.doc;

		if (!doc.preferences) doc.preferences = {}
		if (!doc.preferences.alerts) doc.preferences.alerts = []

		doc.preferences.alerts.push({
			type: '',
			medium: '',
			daysPrior: '',
			hour: '',
			minute: ''
		});
		this.setState({ doc });
	}
	deleteAlert = (e, index) => {
		if (window.confirm('Are you sure you want to delete this alert?')) {
			{
				e.preventDefault();
				e.stopPropagation();

				const doc = this.state.doc;

				if (!(doc.preferences || doc.preferences.alerts
					|| doc.preferences.alerts instanceof Array
					|| doc.preferences.alerts[index]))
					return;

				doc.preferences.alerts.splice(index, 1);
				this.setState({ doc });
			}
		}
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/doctors">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Doctor's Alerts</h1>
				</header>
				<Grid>
					<form autoComplete='on' onSubmit={this.handleSave}>
						{
							(this.state.doc.preferences && this.state.doc.preferences.alerts
								&& this.state.doc.preferences.alerts instanceof Array
								&& this.state.doc.preferences.alerts.length >= 1) ?
								this.state.doc.preferences.alerts.map((alert, i) => {
									return (
										<fieldset className="pages__form--fieldset" key={i}>
											<Row>
												<Col xsOffset={3} smOffset={3} mdOffset={3} lgOffset={3} xs={6} sm={6} md={3} lg={3}>
													<FormGroup>
														<ControlLabel>Type:</ControlLabel>
														<FormControl
															componentClass="select"
															id='type'
															required
															value={alert.type}
															onChange={(e) => this.handleChange(e, i)}>
															<option value=''>--</option>
															<option value='fullsummary'>Full Summary</option>
															<option value='changesonly'>Changes Only</option>
															<option value='disabled'>Disabled</option>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Medium:</ControlLabel>
														<FormControl
															componentClass="select"
															id='medium'
															required
															value={alert.medium}
															onChange={(e) => this.handleChange(e, i)}>
															<option value=''>--</option>
															<option value='emailonly'>Email</option>
															<option value='smsonly'>SMS</option>
															<option value='emailandsms'>Email & SMS</option>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Days prior to appointment&nbsp;<small>(0 - 60 days)</small></ControlLabel>
														<FormControl
															id='daysPrior'
															defaultValue={alert.daysPrior}
															onChange={(e) => this.handleChange(e, i)}
															required
															type='number'
															min="0"
															max="60">
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={3} smOffset={3} mdOffset={0} lgOffset={0} xs={6} sm={6} md={3} lg={3}>
													<FormGroup>
														<ControlLabel>Hour&nbsp;<small>(0 - 23)</small></ControlLabel>
														<FormControl
															id='hour'
															defaultValue={alert.hour}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min="0"
															max="23">
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Minute&nbsp;<small>(0 - 59)</small></ControlLabel>
														<FormControl
															id='minute'
															defaultValue={alert.minute}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min="0"
															max="59">
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col xsOffset={3} smOffset={3} mdOffset={3} lgOffset={3} xs={6} sm={6} md={6} lg={6}
													className={
														this.state.doc.preferences.alerts.length - 1 === i ?
															"pages__form--col settings__list--actionIcons right-text no-border"
															: "pages__form--col settings__list--actionIcons right-text"
													}>
													<FontAwesomeIcon className="settings__list--delete " onClick={(e) => this.deleteAlert(e, i)} icon="trash-alt"></FontAwesomeIcon>
												</Col>
											</Row>
										</fieldset>
									)
								})
								: undefined
						}
						<div className="pages__action-bar--container">
							<div onClick={this.addAlert} className="pages__action-bar--item">
								<FontAwesomeIcon icon="plus" />
							</div>
						</div>
						<div className="pages__form--error centered-text">
							{
								this.state.error && this.state.error instanceof Array ?
									this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
									: <p>{this.state.error.message}</p>
							}
						</div>
						<Row>
							<Col>
								<input className="save-button center-block small-button" type='submit' value='Save' />
							</Col>
						</Row>
					</form>
				</Grid>
			</div>
		);
	}
}

export default withRouter(DoctorAlerts);