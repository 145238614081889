import Amplify, { API, graphqlOperation } from "aws-amplify";
import { AWS_CONFIGURATION } from './../config';
import createTodoMutation from './../mutations/createTodo';
import deleteTodoMutation from './../mutations/deleteTodo';
import updateTodoMutation from './../mutations/updateTodo';
import createdTodo from '../subscriptions/createdTodo';
import deletedTodo from '../subscriptions/deletedTodo';
import updatedTodo from '../subscriptions/updatedTodo';
import { formatRequest } from './../libs/general';

Amplify.configure(AWS_CONFIGURATION);
API.configure(AWS_CONFIGURATION);

export const createTodo = async (body) => {
	try {
		const list = await API.graphql(graphqlOperation(createTodoMutation, {
			body: formatRequest(body)
		}));
		return list.data.createTodo.items;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to create todo');
	}
}

export const updateTodo = async (todoId, body) => {
	try {
		const item = await API.graphql(graphqlOperation(updateTodoMutation, {
			todoId: formatRequest(todoId),
			body: formatRequest(body)
		}));
		return item.data.updateTodo;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to update todo');
	}
}

export const deleteTodo = async (todoId)
	=> await API.graphql(graphqlOperation(deleteTodoMutation, { todoId }));

export const createdTodoSubscription = async (callback) => {
	await API.graphql(
		graphqlOperation(createdTodo)
	).subscribe({
		next: (data) => {
			callback(data.value.data.createdTodo)
		}
	});
};

export const deletedTodoSubscription = async (callback) => {
	await API.graphql(
		graphqlOperation(deletedTodo)
	).subscribe({
		next: (data) => {
			callback(data.value.data.deletedTodo.todoId)
		}
	});
};

export const updatedTodoSubscription = async (callback) => {
	await API.graphql(
		graphqlOperation(updatedTodo)
	).subscribe({
		next: (data) => {
			callback(data.value.data.updatedTodo)
		}
	});
};