export default `
	mutation updateTodo($todoId:ID!,$body:String!){
			updateTodo(todoId:$todoId,
				todo:{
					body:$body
			}){
					todoId
					body
					createdBy
					createdOn
			}
		}
	`