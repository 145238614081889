import React, { Component } from 'react';
import logo from './../../assets/images/logo.jpg';
import { Auth } from 'aws-amplify';
import { logout } from './../../libs/auth';

class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isAuthenticated: false
		}
	}
	componentWillMount = async () => {

		try {
			await Auth.currentAuthenticatedUser();
			this.setState({ isAuthenticated: true });
		} catch (e) {
			this.setState({ isAuthenticated: false });
		}
	}
	handleLogout = () => {
		logout();
	}
	render() {
		return (
			<header>
				<div className='header__logo--container'>
					<img hidden={this.state.isAuthenticated ? "hidden" : ""} src={logo} alt="Medicaid Clinic Logo" />
				</div>
				<div className="header__logout--container">
					<a hidden={!this.state.isAuthenticated ? "hidden" : ""} href='/' onClick={this.handleLogout}>Logout</a>
				</div>
			</header>
		)
	}
}

export default Header;