import React from 'react';

const year = new Date().getFullYear();
const Footer = () => {
	return (
		<footer className="main-footer">
			<p>Copyright&copy; <span>{year}</span>. All rights reserved &nbsp; | &nbsp;
			Developed with <span role="img" aria-label="heart emoji">❤️</span> in Malta by <a href="mailto:info@sp.com.mt">SP Ventures Ltd</a></p>
		</footer>
	)
}

export default Footer;