import PropTypes from 'prop-types'
import React from 'react'
import classes from 'dom-helpers/class'
import getWidth from 'dom-helpers/query/width'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'
import { withRouter } from 'react-router-dom';

import {add, eq, lt, gt, startOf, endOf, range} from 'react-big-calendar/lib/utils/dates'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { inRange } from 'react-big-calendar/lib/utils/eventLevels'
import { isSelected } from 'react-big-calendar/lib/utils/selection'
import moment from 'moment';

class CustomAgendaView extends React.Component {
	static propTypes = {
		events: PropTypes.array,
		date: PropTypes.instanceOf(Date),
		length: PropTypes.number.isRequired,

		selected: PropTypes.object,

		accessors: PropTypes.object.isRequired,
		components: PropTypes.object.isRequired,
		getters: PropTypes.object.isRequired,
		localizer: PropTypes.object.isRequired,
	}

	static defaultProps = {
		length: 30
	}

	componentDidMount() {
		this._adjustHeader()
	}

	componentDidUpdate() {
		this._adjustHeader()
	}
	render() {
		let { length, date, events, accessors, localizer } = this.props
		let { messages } = localizer

		const d2 = moment(date);
		d2.set({
			'hour': 0,
			'minute': 0,
			'second': 0
		});
		date = d2.toDate();

		let end = add(date, length, 'day')

		let rng = range(date, end, 'day')

		events = events.filter(event => moment(date).isSame(moment(event.start), 'day'))

		events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

		return (
			<div className="rbc-agenda-view" style={{zIndex:"50"}}>
				{events.length !== 0 ? (
					<React.Fragment>
						<table ref="header" className="rbc-agenda-table">
							<thead>
								<tr>
									<th className="rbc-header" ref="dateCol">
										{messages.date}
									</th>
									<th className="rbc-header" ref="timeCol">
										{messages.time}
									</th>
									<th className="rbc-header">Patient</th>
								</tr>
							</thead>
							<tbody ref="content">
								{rng.map((day, idx) => this.renderDay(day, events, idx))}
							</tbody>
						</table>
					</React.Fragment>
				) : (
						<span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
					)}
			</div>
		)
	}

	renderDay = (day, events, dayKey) => {
		let {
			selected,
			getters,
			accessors,
			localizer,
			components: { event: Event, date: AgendaDate },
		} = this.props

		events = events.filter(e =>
			inRange(e, startOf(day, 'day'), endOf(day, 'day'), accessors)
		)

		const { showEventTypes } = this.props;

		return events.map((event, idx) => {
			if (showEventTypes.indexOf(event.type) !== -1) {
				let title = accessors.title(event)
				let end = accessors.end(event)
				let start = accessors.start(event)

				const userProps = getters.eventProp(
					event,
					start,
					end,
					isSelected(event, selected)
				)

				let dateLabel = idx === 0 && localizer.format(day, 'agendaDateFormat')
				let first =
					idx === 0 ? (
						<td rowSpan={events.length} className="rbc-agenda-date-cell">
							{AgendaDate ? (
								<AgendaDate day={day} label={dateLabel} />
							) : (
									dateLabel
								)}
						</td>
					) : (
							false
						)

				if (event.idCard) title += " - " + event.idCard;
				if (event.landline && event.landline.landlineNumber) title += " - " + event.landline.landlineNumber;
				if (event.mobile && event.mobile.mobileNumber) title += " - " + event.mobile.mobileNumber;
				if (event.pharmacyRemarks) title += " - " + event.pharmacyRemarks;

				const handleRowClick = (creationTimestamp, item) => {
					if (item && item.type && item.type === "filled") {
						this.props.history.push(`/appointments/${creationTimestamp}`, {
							item,
							refreshRequired: true
						});
					}
				}
				return (

					<tr
						key={dayKey + '_' + idx}
						className={userProps.className}
						style={userProps.style}
						onClick={() => handleRowClick(event.creationTimestamp, event.appointment)}
						name="agendaRow"
					>
						{first}
						<td className="rbc-agenda-time-cell">
							{this.timeRangeLabel(day, event)}
						</td>
						<td className="rbc-agenda-event-cell">
							{Event ? <Event event={event} title={title} /> : title}
						</td>
					</tr>
				)
			} else return null;
		}, [])
	}

	timeRangeLabel = (day, event) => {
		let { accessors, localizer, components } = this.props

		let labelClass = '',
			TimeComponent = components.time,
			label = localizer.messages.allDay

		let end = accessors.end(event)
		let start = accessors.start(event)

		if (!accessors.allDay(event)) {
			if (eq(start, end, 'day')) {
				label = localizer.format({ start, end }, 'agendaTimeRangeFormat')
			} else if (eq(day, start, 'day')) {
				label = localizer.format(start, 'agendaTimeFormat')
			} else if (eq(day, end, 'day')) {
				label = localizer.format(end, 'agendaTimeFormat')
			}
		}

		if (gt(day, start, 'day')) labelClass = 'rbc-continues-prior'
		if (lt(day, end, 'day')) labelClass += ' rbc-continues-after'

		return (
			<span className={labelClass.trim()}>
				{TimeComponent ? (
					<TimeComponent event={event} day={day} label={label} />
				) : (
						label
					)}
			</span>
		)
	}

	_adjustHeader = () => {
		if (!this.refs.tbody) return

		let header = this.refs.header
		let firstRow = this.refs.tbody.firstChild

		if (!firstRow) return

		let isOverflowing =
			this.refs.content.scrollHeight > this.refs.content.clientHeight
		let widths = this._widths || []

		this._widths = [
			getWidth(firstRow.children[0]),
			getWidth(firstRow.children[1]),
		]

		if (widths[0] !== this._widths[0] || widths[1] !== this._widths[1]) {
			this.refs.dateCol.style.width = this._widths[0] + 'px'
			this.refs.timeCol.style.width = this._widths[1] + 'px'
		}

		if (isOverflowing) {
			classes.addClass(header, 'rbc-header-overflowing')
			header.style.marginRight = scrollbarSize() + 'px'
		} else {
			classes.removeClass(header, 'rbc-header-overflowing')
		}
	}
}

CustomAgendaView.range = (start, { length = CustomAgendaView.defaultProps.length }) => {
	const d2 = moment(start);
	d2.set({
		'hour': 0,
		'minute': 0,
		'second': 0
	});
	let end = add(d2, length, 'day')
	return { d2, end }
}

CustomAgendaView.navigate = (date, action, { length = CustomAgendaView.defaultProps.length }) => {
	switch (action) {
		case navigate.PREVIOUS:
			return add(date, -length, 'day')

		case navigate.NEXT:
			return add(date, length, 'day')

		default:
			return date
	}
}

CustomAgendaView.title = (start, { length = CustomAgendaView.defaultProps.length, localizer }) => {
	const d2 = moment(start);
	d2.set({
		'hour': 0,
		'minute': 0,
		'second': 0
	});
	start = d2.toDate();

	let end = add(start, length, 'day')
	return localizer.format({ start, end }, 'agendaHeaderFormat');
}

export default withRouter(CustomAgendaView);