import React, { Component } from 'react';
import { listPending, getAppointment, deleteAppointment } from '../../logic/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Grid, Col, Row } from 'react-bootstrap';


class PendingAppointments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appointmentList: []
		};
	}
	componentWillMount = async () => {
		try {
			const docId = window.localStorage.getItem('currentDoctorId');
			const appointmentList = await listPending(docId);

			this.setState({
				appointmentList
			});
		} catch (e) {
			console.log(e);
		}
	}
	handleImport = async (docId, creationTimestamp) => {
		try {
			const item = await getAppointment(docId, creationTimestamp);

			this.props.history.push({
				pathname: `/appointments/${creationTimestamp}`,
				state: { item }
			});
		} catch (e) {
			console.log(e);
		}
	}
	handleDelete = async (creationTimestamp) => {
		if (window.confirm('Delete this pending appointment?')) {
			const app = await deleteAppointment(window.localStorage.getItem('currentDoctorId'), creationTimestamp);

			if (app instanceof Error)
				this.handleError(app);
			else
				this.props.history.push("/");
		}
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/appointments/new">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Pending Appointments List</h1>
				</header>
				<div className="medium-push"></div>
				<Grid className="pending__container">
					{
						this.state.appointmentList && this.state.appointmentList.length >= 1 ?
							this.state.appointmentList.map((item, i) => {
								return (
									<Row key={i} className="pending__container--item" onClick={() => this.handleImport(item.docId, item.creationTimestamp)}>
										<Col xs={3} sm={3} md={3} lg={3}>
											<Row className="pending__text--name">{item.patient.name.firstName}&nbsp;{item.patient.name.lastName}</Row>
											<Row>{item.patient.idCard}</Row>
										</Col>
										<Col xs={8} sm={8} md={8} lg={8}>
											<Row>
												{
													item.pharmacyRemarks && item.pharmacyRemarks.length > 0 ?
														<p>Medicaid Remarks&nbsp;-&nbsp;{item.pharmacyRemarks}</p>
														: <p className="pending__text--no-comments">No Comments</p>
												}
											</Row>
										</Col>
										<Col xs={1} sm={1} md={1} lg={1}>
											<Row>
												<div className="settings__list--actionIcons">
													<FontAwesomeIcon onClick={() => this.handleDelete(item.creationTimestamp)} className="pending__list--delete" icon="trash-alt" />
												</div>
											</Row>
										</Col>
									</Row>
								)
							})
							: <div className="settings__list--no-tasks-container">
								<p className="settings__list--no-tasks-text">This doctor does not have any pending appointments.</p>
							</div>
					}
				</Grid>
			</div>
		);
	}
}

export default PendingAppointments;