import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Amplify, {API} from 'aws-amplify';
import { withAuthenticator, AmplifyTheme } from 'aws-amplify-react';
import { I18n } from 'aws-amplify';
import { AWS_CONFIGURATION } from './config';
import Main from './components/Main.jsx';
import PendingAppointments from './components/calendar/PendingAppointments.jsx';
import AppointmentDetails from './components/calendar/AppointmentDetails.jsx';
import Settings from './components/settings/Settings.jsx';
import DoctorsList from './components/settings/DoctorsList.jsx';
import ReportsList from './components/settings/ReportsList.jsx';
import Report from './components/settings/Report.jsx';
import DoctorDetails from './components/settings/DoctorDetails.jsx';
import DoctorSchedule from './components/settings/DoctorSchedule.jsx';
import DoctorAlerts from './components/settings/DoctorAlerts.jsx';
import DoctorFields from './components/settings/DoctorFields.jsx';
import DoctorBilling from './components/settings/DoctorBilling.jsx';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faStethoscope, faPencilAlt, faTrashAlt, faPlus, faChevronLeft,
	faCog, faSave, faBan, faListAlt, faPrint, faArrowLeft, faSearch, faTimes, faHandScissors,
	faFilePdf, faBars
} from '@fortawesome/free-solid-svg-icons';

library.add(faStethoscope, faPencilAlt, faTrashAlt, faPlus, faChevronLeft, faCog, faSave,
	faBan, faListAlt, faPrint, faArrowLeft, faSearch, faTimes, faHandScissors, faFilePdf, faBars);

Amplify.configure(AWS_CONFIGURATION);
API.configure(AWS_CONFIGURATION);

const Error = () => {
	return (
		<p>Error: Page does not exist!</p>
	)
}
class App extends Component {
	render() {
		return (
			<BrowserRouter >
				<div>
					<Switch>
						<Route exact path="/" component={Main} />
						<Route exact path="/appointments/new" component={AppointmentDetails} />
						<Route exact path="/appointments/:id/pending" component={PendingAppointments} />
						<Route exact path="/appointments/:id" component={AppointmentDetails} />
						<Route exact path="/settings" component={Settings} />
						<Route exact path="/settings/doctors" component={DoctorsList} />
						<Route exact path="/settings/doctors/:id" component={DoctorDetails} />
						<Route exact path="/settings/doctors/:id/schedule" component={DoctorSchedule} />
						<Route exact path="/settings/doctors/:id/alerts" component={DoctorAlerts} />
						<Route exact path="/settings/doctors/:id/fields" component={DoctorFields} />
						<Route exact path="/settings/doctors/:id/billing" component={DoctorBilling} />
						<Route exact path="/settings/doctors/new" component={DoctorDetails} />
						<Route exact path="/settings/reports" component={ReportsList} />
						<Route exact path="/settings/reports/:id" component={Report} />
						<Route component={Error} />
					</Switch>
				</div>
			</BrowserRouter>
		);
	}
}

const authScreenLabels = {
	en: {
		'Sign in to your account': 'Doctor Scheduling System',
		'Create account': ' ',
		'No account? ': ' ',
		'Sign In': 'LOGIN'
	}
};
I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);

const MyFormContainer = Object.assign({}, AmplifyTheme.formContainer, { marginTop: '0px', marginBottom: '0px' });
const MySectionHeader = Object.assign({}, AmplifyTheme.sectionHeader, { fontSize: '1.7em', backgroundColor: 'white', color: '#343434' });
const MyTheme = Object.assign({}, AmplifyTheme, {
	formContainer: MyFormContainer,
	sectionHeader: MySectionHeader
});

export default withAuthenticator(App, undefined, undefined, undefined, MyTheme);
// export default withAuthenticator(App);
