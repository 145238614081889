import moment from 'moment';

export const nullifyPatient = (params) => {
	const patient = params.patient;
	if (patient.name && !patient.name.firstName) patient.name.firstName = null;
	if (patient.name && !patient.name.lastName) patient.name.lastName = null;
	if (!patient.email) patient.email = null;
	if (!patient.idCard) patient.idCard = null;
	if (!patient.dob) patient.dob = null;
	if (patient.mobile && !patient.mobile.mobileNumber) patient.mobile = null;
	if (patient.landline && !patient.landline.landlineNumber) patient.landline = null;

	return params;
}

export const sortBy = function (path, reverse, primer, then) {
	var get = function (obj, path) {
		if (path) {
			path = path.split('.');
			for (var i = 0, len = path.length - 1; i < len; i++) {
				obj = obj[path[i]];
			};
			return obj[path[len]];
		}
		return obj;
	},
		prime = function (obj) {
			return primer ? primer(get(obj, path)) : get(obj, path);
		};

	return function (a, b) {
		var A = prime(a),
			B = prime(b);

		return (
			(A < B) ? -1 :
				(A > B) ? 1 :
					(typeof then === 'function') ? then(a, b) : 0
		) * [1, -1][+!!reverse];
	};
};

export const clearStorage = () => {
	window.localStorage.removeItem('currentDoctorClinic');
	window.localStorage.removeItem('currentDoctorClinicFee');
	window.localStorage.removeItem('currentDoctorId');
	window.localStorage.removeItem('currentDoctorFields');
	window.localStorage.removeItem('currentDoctorFirstName');
	window.localStorage.removeItem('currentDoctorLastName');
	window.localStorage.removeItem('currentDoctorPayAt');
	window.localStorage.removeItem('currentDoctorRate');
	window.localStorage.removeItem('currentDoctorSchedule');
	window.localStorage.removeItem('currentDoctorServices');
	window.localStorage.removeItem('currentDoctorSpeciality');
	window.localStorage.removeItem('currentDoctorStep');
	window.localStorage.removeItem('currentDoctorTitle');
	window.localStorage.removeItem('firstLoad');
	window.localStorage.removeItem('userId');
	window.localStorage.removeItem('userRights');
}

export const formatRequest = (obj) => {

	if (obj && obj instanceof Object) {
		for (let p in obj) {
			if (obj[p] && obj[p] instanceof Object) obj[p] = formatRequest(obj[p]);
			else if (obj[p] && typeof obj[p] === 'string' && !moment(obj[p]).isValid()) obj[p] = obj[p].toLowerCase().trim();
		}
	} else if (typeof obj === 'string' && !moment(obj).isValid()) {
		obj = obj.toLowerCase().trim();
	}
	return obj;
}

export const camel = (str) => {
	let tempStr = '';
	if (str && typeof str === 'string' && str[0]) {
		tempStr = str[0].toUpperCase();
	} else return str;

	return tempStr + str.substring(1, str.length);
}