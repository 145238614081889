import React, { Component } from 'react';
import { FormControl, Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class NewTodo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			todoBody: '',
			creating: false
		}
	}
	newTodo = () => {
		this.props.newTodo(this.state.todoBody);
		this.setState({ todoBody: '', creating: false });
	}
	clearTodo = () => {
		if (this.state.todoBody.length > 0 && window.confirm("Are you sure you want to cancel?"))
			this.setState({ creating: false, todoBody: '' });
		else if (this.state.todoBody.length <= 0)
			this.setState({ creating: false, todoBody: '' });
		else
			return;
	}
	newTodoChange = (event) => {
		this.setState({ todoBody: event.target.value })
	}
	render() {
		return (

			<Row>
				{
					this.state.creating === false ?
						<Col>
							<div className="pages__action-bar--container">
								<div className="pages__action-bar--item" onClick={() => this.setState({ creating: true })}>
									<FontAwesomeIcon icon="plus" />
								</div>
							</div>
						</Col>
						:
						<div className="todo__list--item">
							<Row>
								<Col xs={10} sm={10} md={10} lg={10}>
									<FormControl
										componentClass="textarea"
										placeholder="Add Task Here"
										value={this.state.todoBody}
										onChange={this.newTodoChange}
										tabIndex={1}
										autoFocus
									>
									</FormControl>
								</Col>
								<Col xs={2} sm={2} md={2} lg={2}>
									<div className="settings__list--actionIcons">
										<FontAwesomeIcon onClick={() => this.newTodo()} className="settings__list--update" icon="save" tabIndex={2} />
										<FontAwesomeIcon onClick={() => this.clearTodo()} className="settings__list--delete" icon="ban" tabIndex={2} />
									</div>
								</Col>
							</Row>
						</div>
				}
			</Row>
		);
	}
}

export default NewTodo;
