import Amplify, { API, graphqlOperation } from "aws-amplify";
import { AWS_CONFIGURATION } from './../config';
import listDoctorsFull from './../queries/listDoctorsFull';
import createDoctorMutation from './../mutations/createDoctor';
import updateDoctorMutation from './../mutations/updateDoctor';
import deleteDoctorMutation from './../mutations/deleteDoctor';
import updateDoctorAvailabilityMutation from './../mutations/updateDoctorAvailability';
import { formatRequest } from './../libs/general';

Amplify.configure(AWS_CONFIGURATION);
API.configure(AWS_CONFIGURATION);

export const getDoctorsFull = async () => {
	try {
		const doctorsList = await API.graphql(graphqlOperation(listDoctorsFull));
		return doctorsList.data.listDoctors.items;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get doctors list');
	}
}

export const createDoctor = async (params) => {
	if (params && params.mobile) params.mobile.countryPrefixCode = 356;
	if (params && params.docId) params.docId = params.docId.toLowerCase();
	if (params && params.email === '') delete params.email;
	try {
		const doctor = await API.graphql(graphqlOperation(createDoctorMutation, formatRequest(params)));

		if (doctor && doctor.errors && doctor.errors instanceof Array && doctor.errors.length >= 1)
			return new Error(doctor.errors);
		else if (doctor && doctor.data && doctor.data.createDoctor)
			return doctor.data.createDoctor;
		else return new Error('Failed to create doctor');
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to create doctor');
	}
}

export const updateDoctor = async (params) => {
	try {
		if (params && params.docId) params.docId = params.docId.toLowerCase();
		if (params && params.email === '') delete params.email;

		const doctor = await API.graphql(graphqlOperation(updateDoctorMutation, formatRequest(params)));

		if (doctor && doctor.errors && doctor.errors instanceof Array && doctor.errors.length >= 1)
			return new Error(doctor.errors);
		else if (doctor && doctor.data && doctor.data.updateDoctor)
			return doctor.data.updateDoctor;
		else return new Error('Failed to update doctor');
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to update doctor');
	}
}

export const deleteDoctor = async (docId)
	=> await API.graphql(graphqlOperation(deleteDoctorMutation, { docId }));

export const updateDoctorAvailability = async (docId, _from, to, type) => {
	try {
		const doctor = await API.graphql(graphqlOperation(updateDoctorAvailabilityMutation, {
			docId: formatRequest(docId), from: formatRequest(_from), to: formatRequest(to), type: formatRequest(type)
		}));

		if (doctor && doctor.errors && doctor.errors instanceof Array && doctor.errors.length >= 1)
			return new Error(doctor.errors);
		else if (doctor && doctor.data && doctor.data.updateAvailability)
			return doctor.data.updateAvailability;
		else return new Error('Failed to update doctor\'s availability');
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to update doctor\'s availability');
	}
}