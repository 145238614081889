export default `
	query listScheduleItems($docId:ID!, $retrievePast:Boolean){
			listScheduleItems(docId:$docId, retrievePast:$retrievePast){
				items{
					docId
					creationTimestamp
					itemId
					fromTimestamp
					toTimestamp
					clinic
					type
					pharmacyRemarks
					doctorRemarks
					patient{
						name{
							firstName
							lastName
						}
						idCard
						dob
						email
						mobile{
							countryPrefixCode
							mobileNumber
						}
						landline{
							countryPrefixCode
							landlineNumber
						}
						remindManually
					}
					billing{
						doctorRate
						clinicFee
						toBePaidAt
						services{
							price
							bought
							currency
							title
						}
					}
				}
			}
		}
	`