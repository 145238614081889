import React, { Component } from 'react';
import {
	Calendar as BigCalendar,
	momentLocalizer
  } from 'react-big-calendar'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from 'moment';
import { getAppointment, getAppointments, createAppointment, deleteAppointment } from './../../logic/calendar';
import { withRouter } from 'react-router-dom';
import CustomAgendaView from './CustomAgendaView.jsx';
import CustomBillingView from './CustomBillingView.jsx';
import { FormGroup, ControlLabel, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomToolbar from './CustomToolbar';
import access from 'safe-access';
import { updateDoctorAvailability } from './../../logic/settings';
import { sortBy, camel } from './../../libs/general';

import Select from 'react-select';
import makeAnimated from 'react-select/lib/animated';

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
moment.locale("en-gb", {
	week: {
		dow: 1 //Monday is the first day of the week.
	}
});

const localizer = momentLocalizer(moment) // or globalizeLocalizer

const formats = {
	agendaHeaderFormat: ({ start, end }, culture, localizer) =>
		`Agenda for ${localizer.format(start, 'ddd MMM Do', culture)}`
}

const eventTypeOptions = [
	{ value: 'filled', label: 'Confirmed appointments' },
	{ value: 'available', label: 'Open time-slots' },
	{ value: 'blocked', label: 'Blocked time-slots' }
];

class Calendar extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentEventTypes: ['available', 'filled', 'blocked', 'auto_available'],
			appointments: [],
			currentView: 'month',
			showPopup: false,
			chosenStart: null,
			chosenEnd: null,
			chosenType: null,
			initTime: null,
			cutAppointment: null,
			day: null,
			firstAvailability: [],
			isSmallScreen: false,
			width: 0,
			height: 0,
			showAvailabilityPopup: false,
			resources: [
				{
					resourceId: 'Clinic 1',
					resourceTitle: 'Clinic 1'
				},
				{
					resourceId: 'Clinic 2',
					resourceTitle: 'Clinic 2'
				},
				{
					resourceId: 'Clinic 3',
					resourceTitle: 'Clinic 3'
				},
				{
					resourceId: 'Clinic 4',
					resourceTitle: 'Clinic 4'
				}
			]
		}
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
		this.handleCutAppointment = this.handleCutAppointment.bind(this);
		this.handlePasteAppointment = this.handlePasteAppointment.bind(this);
	}
	componentWillMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight, isSmallScreen: window.innerWidth < 520 ? true : false });
	}
	componentWillReceiveProps = async () => {
		if (this.state.appointments.length === 0 || 
			window.localStorage.getItem('currentDoctorId') !== window.localStorage.getItem('myDoctorId') ||
			window.localStorage.getItem('currentRetrievePast') !== window.localStorage.getItem('myRetrievePast')){
			try {
				const apps = await getAppointments(window.localStorage.getItem('currentDoctorId'), window.localStorage.getItem('currentRetrievePast'));
				const appointments = [];
				apps.forEach((app) => {
					let title;
					switch (app.type) {
						case "available":
							title = `Available - ${moment(app.fromTimestamp).format('HH:mm')}`;
							break;
						case "auto_available":
							title = `Available - ${moment(app.fromTimestamp).format('HH:mm')}`;
							break;
						case "blocked":
							title = `Blocked - ${moment(app.fromTimestamp).format('HH:mm')}`;
							break;
						case "holiday_blocked":
							title = `Public Holiday - ${moment(app.fromTimestamp).format('HH:mm')}`;
							break;
						default:
							title = `${camel(app.patient.name.firstName)} ${camel(app.patient.name.lastName)} - ${moment(app.fromTimestamp).format('HH:mm')}`;
							break;
					}

					appointments.push({
						title,
						idCard: app.patient && app.patient.idCard ? app.patient.idCard : null,
						mobile: app.patient && app.patient.mobile ? app.patient.mobile : null,
						landline: app.patient && app.patient.landline ? app.patient.landline : null,
						pharmacyRemarks: app.pharmacyRemarks ? app.pharmacyRemarks : null,
						creationTimestamp: app.creationTimestamp ? app.creationTimestamp : null,
						start: new Date(moment(app.fromTimestamp).valueOf()),
						end: new Date(moment(app.toTimestamp).valueOf()),
						allDay: false,
						// resourceId: app.clinic,
						type: app.type,
						appointment: app
					});
				});
				this.getInitTime();
				this.setState({ appointments });
				window.localStorage.setItem('myDoctorId',window.localStorage.getItem('currentDoctorId'));
				window.localStorage.setItem('myRetrievePast',window.localStorage.getItem('currentRetrievePast'));
			} catch (e) {
				console.log(e);
			}
		}
	}
	handleSelectEvent = async (appointment) => {
		let { type, start, end } = appointment;
		if (appointment && type && start && end) {
			start = moment(start);
			end = moment(end);

			this.setState({ chosenAppointment: appointment, chosenType: 'event', chosenStart: start, chosenEnd: end, showPopup: true });
		}
	}
	handleEventStyle = (event) => {
		if (event.type) {
			if (event.type === "blocked")
				return { style: { cursor: "pointer", backgroundColor: "#FE3636", color: 'white', borderColor: "white", borderRadius: "3px" } };
			else if (event.type === "filled")
				return { style: { cursor: "pointer", backgroundColor: "#F6BF26", color: 'white', borderColor: "white", borderRadius: "3px" } };
			else if (event.type === "available" || event.type === "auto_available")
				return { style: { cursor: "pointer", backgroundColor: "#19d477", color: 'white', borderColor: "white", borderRadius: "3px" } };
		} else return;
	}
	handleTodayStyle = (date) => {
		const mDate = moment(date);
		var isCurrentDate = mDate.isSame(new Date(), "day");
		if (isCurrentDate)
			return { style: { backgroundColor: "#F5F5F5", color: '#189AD3' } };
	}
	handleEventTypeChange = (_currentEventTypes) => {
		const currentEventTypes = _currentEventTypes.map((type) => type.value);
		if (currentEventTypes.indexOf('available') !== -1) currentEventTypes.push('auto_available');
		this.setState({ currentEventTypes });
	}
	handlePrint = () => {
		const btnGroup = document.getElementsByClassName('rbc-btn-group');
		if (btnGroup) {
			for (const btn of btnGroup) {
				btn.classList.add("noprint");
			}
		}
		window.print();
	}
	handleSlotClick = ({ start, end, slots }) => {
		if (this.state.currentView !== 'month' && start && end && slots && slots.length === 2) {
			start = moment(start);
			end = moment(end);

			this.setState({ chosenType: 'slot', chosenStart: start, chosenEnd: end, showPopup: true });
		}
	}
	handleReset = (e) => {
		this.handlePopupClick(e, 'reset');
	}
	handleBlocked = (e) => {
		this.handlePopupClick(e, 'blocked');
	}
	handleAvailable = (e) => {
		this.handlePopupClick(e, 'available');
	}
	handlePopupClick = async (e, type) => {
		e.preventDefault();
		this.setState({ error: '' });

		let _from = moment(this.state.chosenStart).format("YYYY-MM-DDTHH:mm:00.000");
		_from = moment(_from).toISOString();

		let _to = moment(this.state.chosenEnd).format("YYYY-MM-DDTHH:mm:00.000");
		_to = moment(_to).toISOString();

		const avail = await updateDoctorAvailability(window.localStorage.getItem('currentDoctorId'), _from, _to, type);

		if (avail instanceof Error)
			console.log(avail);
		else {
			window.localStorage.removeItem('myDoctorId');
			window.localStorage.removeItem('myRetrievePast');
			this.setState({ showPopup: false });
			this.props.refresh();
		}
	}
	handleNewAppointment = async () => {
		const a = this.state.chosenAppointment;
		if (a) {
			if ((a.type === "auto_available" || a.type === "available") && (window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "limitedadmin" || window.localStorage.getItem("userRights") === "doctor")) {
				const item = { type: 'available', fromTimestamp: moment(a.start).toISOString(), toTimestamp: moment(a.end).toISOString() }
				this.props.history.push({
					pathname: `/appointments/new`,
					state: { item }
				});
			} else if (a.type === "filled") {
				const item = await getAppointment(window.localStorage.getItem('currentDoctorId'), a.creationTimestamp);
				this.props.history.push({
					pathname: `/appointments/${a.creationTimestamp}`,
					state: { item }
				});
			}
		}
	}
	firstAvailability = () => {
		const sortedList = this.state.appointments.sort(sortBy('start'));
		const currentDate = moment();
		const firstAvailabilityList = sortedList.filter((app) => {
			const mStart = moment(app.start);
			if ((app.type === 'available' || app.type === 'auto_available')
				&& (mStart.isSameOrAfter(currentDate, 'minute'))) {
				return true;
			} else return false;
		});
		this.setState({ firstAvailabilityList, showAvailabilityPopup: true });
	}
	closeFirstAvailabilityPopup = () => {
		this.setState({ firstAvailabilityList: [], showAvailabilityPopup: false });
	}
	handleFirstAvailabilityClick = (item) => {
		this.setState({ chosenAppointment: item }, () => this.handleNewAppointment(item));
	}
	getInitTime() {
		const initTime = window.localStorage['tempFromTimestamp'] ?
			new Date(moment(window.localStorage['tempFromTimestamp']).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'))
			: new Date(moment().subtract(1, 'hour'));

		window.localStorage.removeItem('tempFromTimestamp');
		this.setState({ initTime, day: initTime });
	}
	handleCutAppointment(){
		this.setState({cutAppointment: this.state.chosenAppointment, showPopup: false});
	}
	handlePasteAppointment = async()=> {
		const cutAppointment = this.state.cutAppointment;
		const chosenAppointment = this.state.chosenAppointment;

		if (cutAppointment && (chosenAppointment.type === 'available' || chosenAppointment.type === 'auto_available')){
			try{
				cutAppointment.appointment.fromTimestamp = chosenAppointment.appointment.fromTimestamp;
				cutAppointment.appointment.toTimestamp = chosenAppointment.appointment.toTimestamp;
				cutAppointment.appointment.creationTimestamp = chosenAppointment.appointment.creationTimestamp;
				
				const docId = cutAppointment.appointment.docId;

				await createAppointment(docId,cutAppointment.appointment);
				await deleteAppointment(docId,cutAppointment.creationTimestamp);
				this.setState({ showPopup: false, cutAppointment: null });
				window.localStorage.removeItem('myDoctorId')
				window.localStorage.removeItem('myRetrievePast')
				this.props.refresh();
			}catch(e){
				console.log(e);
			}
		}
	}
	render() {
		return (
			window.localStorage.getItem('currentDoctorId') && !this.props.hidden ?
				<div className="calendar__container">
					{
						(this.state.currentView === "agenda" || this.state.currentView === "billing") && !this.props.hidden ?
							<Row className="calendar__agenda--functions-container noprint">
								{
									this.state.currentView === "agenda" ?
										<Col xs={12} sm={10} md={8}>
											<FormGroup>
												<ControlLabel htmlFor="eventTypes">Show the following:</ControlLabel>
												<Select
													id="eventTypes"
													closeMenuOnSelect={false}
													components={makeAnimated()}
													defaultValue={eventTypeOptions}
													isMulti
													options={eventTypeOptions}
													onChange={this.handleEventTypeChange}
													placeholder="Click here to choose what to show in the agenda"
												/>
											</FormGroup>
										</Col>
										: false
								}

								<Col xs={this.state.currentView === "agenda" ? 2 : 12} className="calendar__agenda--print-container">
									<div className="calendar__agenda--print-icon" onClick={this.handlePrint} to="/settings">
										<FontAwesomeIcon icon="print" />
									</div>
								</Col>
							</Row>
							: null
					}
					{
						!this.props.hidden ?
							<div style={{ height: "100%" }}>
								<BigCalendar
									id="calendar"
									localizer={localizer}
									events={this.state.appointments}
									onView={(currentView) => this.setState({ currentView })}
									defaultView={this.state.isSmallScreen ? "day" : "month"}
									// resources={this.state.resources}
									resourceIdAccessor="resourceId"
									// resourceTitleAccessor="resourceTitle"
									startAccessor="start"
									endAccessor="end"
									onSelectEvent={this.handleSelectEvent}
									eventPropGetter={this.handleEventStyle}
									dayPropGetter={this.handleTodayStyle}
									step={parseInt(window.localStorage.getItem('currentDoctorStep'), 10)}
									timeslots={1}
									length={1}
									formats={formats}
									views={
										this.state.isSmallScreen ?
											{ day: true, agenda: CustomAgendaView, billing: CustomBillingView }
											: { month: true, week: true, day: true, agenda: CustomAgendaView, billing: CustomBillingView }
									}
									showEventTypes={this.state.currentEventTypes}
									components={{
										toolbar: (toolbar) => CustomToolbar(toolbar, this.firstAvailability)
									}}
									selectable={true}
									onSelectSlot={this.handleSlotClick}
									onNavigate={(day) => {
										this.getInitTime();
										this.setState({
											day,
										});
									}}
									// date={new Date(moment(this.state.day).format())}
									scrollToTime={this.state.initTime}
								// min={moment(284018400000).toDate()} // shows calendar from 7AM onwards only --- breaks scrollToTime so commented out
								/>
								{
									this.state.showPopup ?
										<div className="calendar__popup--container">
											<div className="calendar__popup--popup">
												<p className="calendar__popup--date">{this.state.chosenStart.format('DD MMM YYYY')}</p>
												<p className="calendar__popup--date">{this.state.chosenStart.format('HH:mm')} to {this.state.chosenEnd.format('HH:mm')}</p>
												<button
													id="cut_appointment"
													className={
														(this.state.chosenType !== 'slot' && (window.localStorage.getItem("userRights")==="admin" || window.localStorage.getItem("userRights")==="limitedadmin" ) && (access(this,'state.chosenAppointment.type') === 'filled' || ((access(this,'state.chosenAppointment.type') === 'available' || access(this,'state.chosenAppointment.type') === 'auto_available') && this.state.cutAppointment))) ?
															"save-button center-block large-button calendar__popup--cut-appointment-button" :
															"save-button center-block large-button calendar__popup--cut-appointment-button disabled"
													}
													disabled={!(this.state.chosenType !== 'slot' && (window.localStorage.getItem("userRights")==="admin" || window.localStorage.getItem("userRights")==="limitedadmin" ) && (access(this,'state.chosenAppointment.type') === 'filled' || ((access(this,'state.chosenAppointment.type') === 'available' || access(this,'state.chosenAppointment.type') === 'auto_available') && this.state.cutAppointment)))}
													onClick={this.state.cutAppointment && (access(this,'state.chosenAppointment.type') === 'available' || access(this,'state.chosenAppointment.type') === 'auto_available') ? this.handlePasteAppointment : this.handleCutAppointment}>
													{access(this,'state.chosenAppointment.type') === 'available' || access(this,'state.chosenAppointment.type') === 'auto_available' ? 'Paste' : 'Cut'} appointment<span></span>
												</button>
												<button
													id="reset"
													className={
														(window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && this.state.chosenType !== 'slot' && access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'auto_available' ?
															"save-button center-block large-button calendar__popup--reset-button" :
															"save-button center-block large-button calendar__popup--reset-button disabled"
													}
													disabled={!((window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && this.state.chosenType !== 'slot' && access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'auto_available')}
													onClick={this.handleReset}

												>
													Reset slot<span></span></button>
												<button
													id="mark_available"
													className={
														(window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && (this.state.chosenType === 'slot' || (access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'available' && access(this, 'state.chosenAppointment.type') !== 'auto_available')) ?
															"save-button center-block large-button calendar__popup--available-button" :
															"save-button center-block large-button calendar__popup--available-button disabled"
													}
													disabled={!((window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && (this.state.chosenType === 'slot' || (access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'available' && access(this, 'state.chosenAppointment.type') !== 'auto_available')))}
													onClick={this.handleAvailable}>Mark as available<span></span></button>
												<button
													id="mark_blocked"
													className={
														(window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && (this.state.chosenType === 'slot' || (access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'blocked')) ?
															"save-button large-button calendar__popup--blocked-button center-block" :
															"save-button large-button calendar__popup--blocked-button center-block disabled"
													}
													disabled={!((window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "doctor") && (this.state.chosenType === 'slot' || (access(this, 'state.chosenAppointment.type') !== 'filled' && access(this, 'state.chosenAppointment.type') !== 'blocked')))}
													onClick={this.handleBlocked}>Mark as blocked<span></span></button>
												<button
													id="new_edit_appointment"
													className={
														((window.localStorage.getItem("userRights")==="doctor" && access(this,'state.chosenAppointment.type') === 'filled') || window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "limitedadmin" || window.localStorage.getItem("userRights") === "doctor") && access(this, 'state.chosenAppointment.type') !== 'blocked' && access(this, 'state.chosenAppointment.type') !== 'holiday_blocked' && access(this, 'state.chosenType') !== 'slot' ?
															"save-button center-block large-button calendar__popup--new-appointment-button" :
															"save-button center-block large-button calendar__popup--new-appointment-button disabled"
													}
													disabled={!(((window.localStorage.getItem("userRights")==="doctor" && access(this,'state.chosenAppointment.type') === 'filled') || window.localStorage.getItem("userRights") === "admin" || window.localStorage.getItem("userRights") === "limitedadmin" || window.localStorage.getItem("userRights") === "doctor") && access(this, 'state.chosenAppointment.type') !== 'blocked' && access(this, 'state.chosenType') !== 'slot')}
													onClick={this.handleNewAppointment}>{this.state.chosenAppointment && this.state.chosenAppointment.type === 'filled' ? 'Edit' : 'New'} appointment<span></span>
												</button>
												<p className="calendar__popup--cancel-button" onClick={() => this.setState({ showPopup: false })}>Cancel</p>
											</div>
										</div>
										: this.state.showAvailabilityPopup ?
											<div className="calendar__availability-popup--container">
												<div className="calendar__availability-popup--popup">
													<p className="calendar__availability-popup--title">Next available slots</p>
													<p className="calendar__availability-popup--sub-title">Only showing the first 5 available slots for the next few months</p>
													{
														this.state.firstAvailabilityList.length > 0 ?
															this.state.firstAvailabilityList.map((item, i) => {
																if (i > 4) return undefined;
																return [
																	<div
																		className="calendar__availability-popup-item"
																		onClick={() => this.handleFirstAvailabilityClick(item)}
																		key={'first_availability_' + i}>
																		<p>{moment(item.start).format('Do MMM YYYY - HH:mm')}</p>
																	</div>
																]
															})
															: <p>No available slots in the next few months</p>
													}
													<p className="calendar__popup--cancel-button" onClick={this.closeFirstAvailabilityPopup}>Close</p>
												</div>
											</div>
											: undefined
								}
							</div>
							: undefined
					}
				</div>
				:
				this.props.calendarOrTasks === "calendar" ?
					<div className="calendar__no-doctor--container">
						<p>Please choose a doctor from the list</p>
						<FontAwesomeIcon icon="arrow-left" />
					</div>
					: false

		)
	}
}

export default withRouter(Calendar);