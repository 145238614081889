export default `
	mutation updateDoctor($docId:ID!, $title: Title!, $clinic:String, $name:NameInput!, $speciality: String!, $email: AWSEmail, $mobile:MobileInput!,$preferences:PreferencesInput,$isFavourite:Boolean!) {
		updateDoctor(docId:$docId,doctor: {email:$email,clinic:$clinic,title: $title, name: $name, speciality: $speciality, mobile: $mobile, preferences: $preferences, isFavourite: $isFavourite}) {
			docId
			email
			title
			name{
				firstName
				lastName
			}
			speciality
			mobile{
				countryPrefixCode
				mobileNumber
			}
			clinic
		}
	}
`