export default `
	query scheduleItemsReport($date:AWSDate!,$nextToken:String){
			scheduleItemsReport(date:$date,nextToken:$nextToken){
					nextToken
					items{
					docId
					creationTimestamp
					itemId
					fromTimestamp
					toTimestamp
					clinic
					reminders{
						email{
							lastSentTimestamp
							lastStatus
						}
						sms{
							lastSentTimestamp
							lastStatus
						}
					}
					patient{
						name{
							firstName
							lastName
						}
						email
						mobile{
							countryPrefixCode
							mobileNumber
						}
						landline{
							countryPrefixCode
							landlineNumber
						}
						idCard
						dob
						remindManually
					}
					}
				}
			}
		`