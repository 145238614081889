import React from 'react';
import ReactDOM from 'react-dom';
import './styles/main.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import ImportedApp from './App';
import registerServiceWorker from './registerServiceWorker';
import Header from './components/common/Header.jsx';
import Footer from './components/common/Footer.jsx';
import ROAppointmentDetails from './components/readOnlyView/ROAppointmentDetails.jsx';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			roView: false,
			d: null,
			ct: null,
			i: null
		}
	}
	componentWillMount = async () => {
		let roView = false;
		if (window.location.href.indexOf('view=ro') !== -1) {
			roView = true;
			const url = new URL(window.location.href);
			var d = url.searchParams.get("d");
			var ct = url.searchParams.get("ct");
			var i = url.searchParams.get("i");

			this.setState({ roView, d, ct, i });
		}
	}
	render() {
		return (
			this.state.roView ?
				<div>
					<ROAppointmentDetails d={this.state.d} ct={this.state.ct} i={this.state.i} ></ROAppointmentDetails>
				</div>
				:
				<div>
					<Header></Header>
					<ImportedApp></ImportedApp>
					<Footer></Footer>
				</div>
		)
	}
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
