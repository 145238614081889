export default `
	mutation createScheduleItem($docId:ID!,$item:ScheduleItemInput!) {
		createScheduleItem(
      		docId:$docId
      		item:$item
    ) {
    	docId
    	creationTimestamp
	}
}
`