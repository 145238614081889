import Amplify, { API, graphqlOperation } from "aws-amplify";
import { AWS_RO_CONFIGURATION } from './../config';

import getROAppointmentQuery from './../queries/getROAppointment';

if (window.location.href.indexOf('view=ro') !== -1) {
	Amplify.configure(AWS_RO_CONFIGURATION);
	API.configure(AWS_RO_CONFIGURATION);
}
export const getROItem = async (docId, creationTimestamp, itemId) => {
	try {
		const appointment = await API.graphql(graphqlOperation(getROAppointmentQuery, { docId, creationTimestamp, itemId }));
		window.localStorage.setItem('currentDoctorFirstName', appointment.data.getScheduleItem.doctor.name.firstName);
		window.localStorage.setItem('currentDoctorLastName', appointment.data.getScheduleItem.doctor.name.lastName);
		window.localStorage.setItem('currentDoctorTitle', appointment.data.getScheduleItem.doctor.title);
		return appointment.data.getScheduleItem.item;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get appointment');
	}
}