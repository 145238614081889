import PropTypes from 'prop-types'
import React from 'react'
import classes from 'dom-helpers/class'
import getWidth from 'dom-helpers/query/width'
import scrollbarSize from 'dom-helpers/util/scrollbarSize'
import { withRouter } from 'react-router-dom';

import {eq, lt, gt, range, startOf, endOf, add} from 'react-big-calendar/lib/utils/dates'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { inRange } from 'react-big-calendar/lib/utils/eventLevels'
import moment from 'moment';
import access from 'safe-access';

class CustomBillingView extends React.Component {
	static propTypes = {
		events: PropTypes.array,
		date: PropTypes.instanceOf(Date),
		length: PropTypes.number.isRequired,

		selected: PropTypes.object,

		accessors: PropTypes.object.isRequired,
		components: PropTypes.object.isRequired,
		getters: PropTypes.object.isRequired,
		localizer: PropTypes.object.isRequired,
	}

	static defaultProps = {
		length: 30
	}

	componentDidMount() {
		this._adjustHeader()
	}

	componentDidUpdate() {
		this._adjustHeader()
	}
	render() {
		let { length, date, events, accessors, localizer } = this.props
		let { messages } = localizer

		const d2 = moment(date);
		d2.set({
			'hour': 0,
			'minute': 0,
			'second': 0
		});
		date = d2.toDate();

		let end = add(date, length, 'day')

		let rng = range(date, end, 'day')

		events = events.filter(event => moment(date).isSame(moment(event.start), 'day'))

		events.sort((a, b) => +accessors.start(a) - +accessors.start(b))

		return (
			<div className="rbc-agenda-view">
				{events.filter((e) => e.type === "filled").length !== 0 ? (
					<React.Fragment>
						<table ref="header" className="rbc-agenda-table">
							<thead>
								<tr>
									<th className="rbc-header" ref="dateCol">
										{messages.date}
									</th>
									<th className="rbc-header" ref="timeCol">
										{messages.time}
									</th>
									<th className="rbc-header">Patient</th>
									<th className="rbc-header">Doctor Fee</th>
									<th className="rbc-header">Clinic Fee</th>
									<th className="rbc-header">Services</th>
									<th className="rbc-header">Total</th>

								</tr>
							</thead>

							<tbody ref="content">

								{rng.map((day, idx) => this.renderDay(day, events, idx))}

							</tbody>
							<tfoot>
								{rng.map((day, idx) => this.renderTotals(day, events, idx))}
							</tfoot>
						</table>
					</React.Fragment>
				) : (
						<span className="rbc-agenda-empty">{messages.noEventsInRange}</span>
					)}
			</div>
		)
	}

	renderDay = (day, events, dayKey) => {
		let {
			accessors,
		} = this.props

		events = events.filter(e =>
			inRange(e, startOf(day, 'day'), endOf(day, 'day'), accessors)
		)

		return events.map((event, idx) => {
			if (event.type === "filled") {


				const handleRowClick = (creationTimestamp, item) => {
					if (item && item.type && item.type === "filled") {
						this.props.history.push(`/appointments/${creationTimestamp}`, {
							item,
							refreshRequired: true
						});
					}
				}
				const doctorFee = access(event, 'appointment.billing.doctorRate') || 0;
				const clinicFee = access(event, 'appointment.billing.clinicFee') || 0;
				const services = access(event, 'appointment.billing.services');

				let servicesBill = 0;
				if (services) {
					for (const service of services) {
						if (service.bought === "true" || service.bought === true) servicesBill += service.price;
					}
				}
				const total = doctorFee + clinicFee + servicesBill;

				return (

					<tr
						key={dayKey + '_' + idx}
						onClick={() => handleRowClick(event.creationTimestamp, event.appointment)}
						name="agendaRow"
					>
						<td>
							{moment(event.start).format('ddd MMM Do')}
						</td>
						<td>
							{moment(event.start).format('hh:mm a') + ' -- ' + moment(event.end).format('hh:mm a')}
						</td>
						<td>
							{event.title}
						</td>
						<td>{doctorFee ? <p>&euro;&nbsp;{doctorFee}</p> : ''}</td>
						<td>{clinicFee ? <p>&euro;&nbsp;{clinicFee}</p> : ''}</td>
						<td>{servicesBill ? <p>&euro;&nbsp;{servicesBill}</p> : ''}</td>
						<td>{total ? <p>&euro;&nbsp;{total}</p> : ''}</td>
					</tr>
				)
			} else return null;
		}, [])
	}

	renderTotals = (day, events, dayKey) => {
		let {
			accessors,
		} = this.props

		events = events.filter(e =>
			inRange(e, startOf(day, 'day'), endOf(day, 'day'), accessors)
		)

		const docTotals = {
			doctorFee: 0,
			clinicFee: 0,
			servicesBill: 0,
			total: 0
		}
		events.forEach((event, idx) => {
			if (event.type === "filled") {

				const doctorFee = access(event, 'appointment.billing.doctorRate') || 0;
				const clinicFee = access(event, 'appointment.billing.clinicFee') || 0;
				const services = access(event, 'appointment.billing.services');

				let servicesBill = 0;
				if (services) {
					for (const service of services) {
						if (service.bought === "true" || service.bought === true) servicesBill += service.price;
					}
				}
				const total = doctorFee + clinicFee + servicesBill;

				docTotals.doctorFee += doctorFee;
				docTotals.clinicFee += clinicFee;
				docTotals.servicesBill += servicesBill;
				docTotals.total += total;
			}
		});

		return (

			<tr>
				<td className="calendar__billing--totals-cell"></td>
				<td className="calendar__billing--totals-cell"></td>
				<td className="calendar__billing--totals-cell"></td>
				<td className="calendar__billing--totals-cell">{docTotals.doctorFee ? <p>&euro;&nbsp;{docTotals.doctorFee}</p> : ''}</td>
				<td className="calendar__billing--totals-cell">{docTotals.clinicFee ? <p>&euro;&nbsp;{docTotals.clinicFee}</p> : ''}</td>
				<td className="calendar__billing--totals-cell">{docTotals.servicesBill ? <p>&euro;&nbsp;{docTotals.servicesBill}</p> : ''}</td>
				<td className="calendar__billing--totals-cell">{docTotals.total ? <p>&euro;&nbsp;{docTotals.total}</p> : ''}</td>
			</tr>
		)
	}

	timeRangeLabel = (day, event) => {
		let { accessors, localizer, components } = this.props

		let labelClass = '',
			TimeComponent = components.time,
			label = localizer.messages.allDay

		let end = accessors.end(event)
		let start = accessors.start(event)

		if (!accessors.allDay(event)) {
			if (eq(start, end, 'day')) {
				label = localizer.format({ start, end }, 'agendaTimeRangeFormat')
			} else if (eq(day, start, 'day')) {
				label = localizer.format(start, 'agendaTimeFormat')
			} else if (eq(day, end, 'day')) {
				label = localizer.format(end, 'agendaTimeFormat')
			}
		}

		if (gt(day, start, 'day')) labelClass = 'rbc-continues-prior'
		if (lt(day, end, 'day')) labelClass += ' rbc-continues-after'

		return (
			<span className={labelClass.trim()}>
				{TimeComponent ? (
					<TimeComponent event={event} day={day} label={label} />
				) : (
						label
					)}
			</span>
		)
	}

	_adjustHeader = () => {
		if (!this.refs.tbody) return

		let header = this.refs.header
		let firstRow = this.refs.tbody.firstChild

		if (!firstRow) return

		let isOverflowing =
			this.refs.content.scrollHeight > this.refs.content.clientHeight
		let widths = this._widths || []

		this._widths = [
			getWidth(firstRow.children[0]),
			getWidth(firstRow.children[1]),
		]

		if (widths[0] !== this._widths[0] || widths[1] !== this._widths[1]) {
			this.refs.dateCol.style.width = this._widths[0] + 'px'
			this.refs.timeCol.style.width = this._widths[1] + 'px'
		}

		if (isOverflowing) {
			classes.addClass(header, 'rbc-header-overflowing')
			header.style.marginRight = scrollbarSize() + 'px'
		} else {
			classes.removeClass(header, 'rbc-header-overflowing')
		}
	}
}

CustomBillingView.range = (start, { length = CustomBillingView.defaultProps.length }) => {
	const d2 = moment(start);
	d2.set({
		'hour': 0,
		'minute': 0,
		'second': 0
	});
	let end = add(d2, length, 'day')
	return { d2, end }
}

CustomBillingView.navigate = (date, action, { length = CustomBillingView.defaultProps.length }) => {
	switch (action) {
		case navigate.PREVIOUS:
			return add(date, -length, 'day')

		case navigate.NEXT:
			return add(date, length, 'day')

		default:
			return date
	}
}

CustomBillingView.title = (start, { length = CustomBillingView.defaultProps.length, localizer }) => {
	const d2 = moment(start);
	d2.set({
		'hour': 0,
		'minute': 0,
		'second': 0
	});
	start = d2.toDate();

	return `Billing Report for ${d2.format('ddd MMM Do')}`;
}

export default withRouter(CustomBillingView);