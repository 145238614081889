export default `
	mutation createTodo($body:String!){
			createTodo(todo:{
					body:$body
			}){
					todoId
					body
					createdBy
					createdOn
			}
		}
	`