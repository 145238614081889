import React, { Component } from 'react';
import DoctorList from './calendar/DoctorList.jsx';
import Calendar from './calendar/Calendar.jsx';
import Todos from './todos/Todos.jsx';
import { getInitialInfo } from './../logic/calendar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { sortBy, camel } from './../libs/general';

class Main extends Component {
	constructor(props) {
		super(props);
		this.state = {
			alphabeticalList: [],
			favouritesList: [],
			todosList: [],
			currentView: 'calendar',
			isSidebarOpen: true
		};
	}
	componentWillMount = async () => {

		const isSidebarOpen = this.isSmallScreen();

		if (!window.localStorage.getItem('firstLoad')) {
			window.localStorage.setItem('firstLoad', "false");
			document.location.reload();
		}

		try {
			const initialInfo = await getInitialInfo();

			if (initialInfo.favouritesList) initialInfo.favouritesList = initialInfo.favouritesList.sort(sortBy("name.lastName"));
			if (initialInfo.alphabeticalList) initialInfo.alphabeticalList = initialInfo.alphabeticalList.sort(sortBy("name.lastName"));

			this.setState({
				alphabeticalList: initialInfo.alphabeticalList,
				favouritesList: initialInfo.favouritesList,
				todosList: initialInfo.todosList,
				isSidebarOpen
			});

		} catch (e) {
			console.log(e);
		}
	}
	isSmallScreen = () => {
		return Math.max(document.documentElement.clientWidth, window.innerWidth || 0) < 770 ? false:true;
	}
	handleTodoDelete = (todoId) => {
		const todosList = this.state.todosList.filter((todo) => todo.todoId !== todoId);
		this.setState({ todosList });
	}
	refresh = () => {
		this.forceUpdate();
	}
	showTasksView = () => {
		this.setState({ currentView: 'tasks' });
		this.closeSidebar();
	}
	showCalendarView = () => {
		this.setState({ currentView: 'calendar' });
		this.closeSidebar();
	}
	toggleSidebar = () => {
		const isSidebarOpen = !this.state.isSidebarOpen;
		this.setState({ isSidebarOpen });
	}
	closeSidebar = () => {
		if (this.isSmallScreen()){
			this.setState({isSidebarOpen: false});
		}
	}
	render() {
		return (
			<div> {/* whole page */}
				<Row>
					<Col xs={1} style={{marginLeft:"20px"}}>
						<FontAwesomeIcon className="calendar__sidebar--toggle" icon="bars" onClick={this.toggleSidebar} />
					</Col>
					<Col xs={8} xsPush={1}>
						{
							window.localStorage.getItem('currentDoctorId') ?
								<p className="calendar__doctor-title--text"><span>{camel(window.localStorage.getItem("currentDoctorTitle"))}.&nbsp;{camel(window.localStorage.getItem("currentDoctorLastName"))},&nbsp;{camel(window.localStorage.getItem("currentDoctorFirstName"))}</span></p>
								: false
						}
					</Col>
				</Row>
				<Row>
					{
						this.state.isSidebarOpen ?
							<Col xs={12} md={2} style={{zIndex:"999"}}>
								<div className="calendar__doctor-lists--container noprint"> {/* doctor lists */}
									<h4>Favourites</h4>
									<div className="calendar__doctor-lists--list"> {/* favourites */}
										{this.renderFavouritesList()}
									</div>
									<hr></hr>
									<h4>Alphabetical</h4>
									<div className="calendar__doctor-lists--list"> {/* alphabetical */}
										{this.renderAlphabeticalList()}
									</div>
								</div>
							</Col>
							: false
					}
					<Col xs={this.state.isSidebarOpen ? 0 : 12} md={this.state.isSidebarOpen ? 10 : 12} hidden={!this.state.currentView === 'tasks'}>
						{this.renderTodosList()}
					</Col>
					<Col xs={this.state.isSidebarOpen ? 0 : 12} md={this.state.isSidebarOpen ? 10 : 12} hidden={!this.state.currentView === 'calendar'}>
						{this.renderCalendar()}
					</Col>
				</Row>

				<div className="tiny-push"></div>
				<div style={{zIndex:"999"}} className="pages__action-bar--container noprint">
					<div className="calendar__view-nav--buttons">
						{
							(window.localStorage.getItem('userRights') === "admin" || window.localStorage.getItem("userRights") === "limitedadmin") ?
								<button onClick={this.showTasksView} className="my-button">Tasks
										{
										this.state.todosList && this.state.todosList instanceof Array && this.state.todosList.length !== 0 ?
											<p id="todos-number" style={this.state.todosList.length ? { display: false } : { display: 'none' }}>{this.state.todosList.length}</p>
											: undefined
									}
								</button>
								: null
						}
						<button onClick={this.showCalendarView} className="my-button">Calendar</button>
					</div>
					{
						window.localStorage.getItem('userRights') === "admin" || window.localStorage.getItem('userRights') === "limitedadmin" ?
							<div>
								<Link className="pages__action-bar--item neutral-button" to="/settings">
									<FontAwesomeIcon icon="cog" />
								</Link>
								<div className="tiny-push"></div>
							</div>

							: null
					}				
				</div>				
				<div className="medium-push"></div>
			</div >

		);
	}
	renderAlphabeticalList = () => {
		return <DoctorList closeSidebar={this.closeSidebar} refresh={this.refresh} showCalendarView={this.showCalendarView} list={this.state.alphabeticalList} />
	};
	renderFavouritesList = () => {
		return <DoctorList closeSidebar={this.closeSidebar} refresh={this.refresh} showCalendarView={this.showCalendarView} list={this.state.favouritesList} />
	};
	renderTodosList = () => {
		return <Todos closeSidebar={this.closeSidebar} handleTodoDelete={this.handleTodoDelete} list={this.state.todosList} hidden={this.state.currentView === 'tasks' ? false : true} />
	};
	renderCalendar = () => {
		return <Calendar closeSidebar={this.closeSidebar} refresh={this.refresh} calendarOrTasks={this.state.currentView} hidden={this.state.currentView === 'calendar' ? false : true} />
	};
}

export default Main;
