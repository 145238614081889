import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getDoctorsFull, deleteDoctor } from './../../logic/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearStorage } from './../../libs/general';

class DoctorsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doctorsList: []
		};
	}
	componentWillMount = async () => {
		try {
			const doctorsList = await getDoctorsFull();

			this.setState({
				doctorsList
			});
		} catch (e) {
			console.log(e);
		}
	}
	handleDelete = async (docId) => {
		if (window.confirm('Are you sure you want to delete this doctor?')) {
			try {
				await deleteDoctor(docId);
				clearStorage();
				const doctorsList = this.state.doctorsList.filter((doc) => doc.docId !== docId);
				this.setState({ doctorsList });
			} catch (e) {
				console.log(e);
			}
		}
	}
	handleUpdate = (doc) => {
		doc.firstName = doc.name.firstName;
		doc.lastName = doc.name.lastName;
		doc.mobileNumber = doc.mobile.mobileNumber;
		this.props.history.push({
			pathname: `/settings/doctors/${doc.docId}`,
			state: { doc }
		});
	}
	handleSchedule = (doc) => {
		this.props.history.push({
			pathname: `/settings/doctors/${doc.docId}/schedule`,
			state: { doc }
		});
	}
	handleAlerts = (doc) => {
		this.props.history.push({
			pathname: `/settings/doctors/${doc.docId}/alerts`,
			state: { doc }
		});
	}
	handleFields = (doc) => {
		this.props.history.push({
			pathname: `/settings/doctors/${doc.docId}/fields`,
			state: { doc }
		});
	}
	handleBilling = (doc) => {
		this.props.history.push({
			pathname: `/settings/doctors/${doc.docId}/billing`,
			state: { doc }
		});
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Doctor List</h1>
				</header>
				<div className="settings__list--container">
					{
						this.state.doctorsList ? this.state.doctorsList.map((doc, i) => {
							return (
								<div className="settings__list--item" key={i}>
									<div className="settings__list--docNameSpeciality">
										<h3 className="settings__list--docName">{doc.title}.&nbsp;{doc.name.lastName},&nbsp;{doc.name.firstName}</h3>
										<h4 className="settings__list--docSpeciality">{doc.speciality}</h4>
									</div>
									<button onClick={() => this.handleSchedule(doc)}>Schedule</button>
									<button onClick={() => this.handleAlerts(doc)}>Alerts</button>
									<button onClick={() => this.handleFields(doc)}>Fields</button>
									<button onClick={() => this.handleBilling(doc)}>Billing</button>
									<div className="settings__list--actionIcons">
										<FontAwesomeIcon onClick={() => this.handleUpdate(doc)} className="settings__list--update" icon="pencil-alt" />
										<FontAwesomeIcon onClick={() => this.handleDelete(doc.docId)} className="settings__list--delete" icon="trash-alt" />
									</div>
								</div>
							)
						})
							: null
					}
				</div>
				<div className="pages__action-bar--container">
					<Link className="pages__action-bar--item" to="/settings/doctors/new">
						<FontAwesomeIcon icon="plus" />
					</Link>
				</div>
				<div className="large-push"></div>
			</div>
		);
	}
}

export default DoctorsList;