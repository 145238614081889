export default `
	query searchScheduleItems($docId:ID!,$query:String!){
			searchScheduleItems(docId:$docId, query:$query){
				nextToken
				items{
					creationTimestamp
					fromTimestamp
					toTimestamp
					patient{
						name{
							firstName
							lastName
						}
						idCard
					}			
				}
			}
		}
	`