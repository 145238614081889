export default `
	query GetScheduleItem($docId:ID!,$creationTimestamp:AWSDateTime!,$itemId:ID!) {
    getScheduleItem(docId:$docId,
      creationTimestamp:$creationTimestamp,
      itemId:$itemId){
   item{
     docId
    itemId
    creationTimestamp
    fromTimestamp
    toTimestamp
    clinic
    patient{
      idCard
      dob
      mobile{
        countryPrefixCode
        mobileNumber
      }
      landline{
        countryPrefixCode
        landlineNumber
      }
      email
      name {
          firstName
          lastName
        }
    	}
    }
    doctor{
      name{
        firstName
        lastName
      }
      title
    }
    }
}
		`