import Amplify, { API, graphqlOperation } from "aws-amplify";
import { AWS_CONFIGURATION } from './../config';

import scheduleItemsReportQuery from './../queries/scheduleItemsReport';
import visitingDoctorsReportQuery from './../queries/visitingDoctorsReport';
import getReportDoctorsQuery from './../queries/getReportDoctors';

Amplify.configure(AWS_CONFIGURATION);
API.configure(AWS_CONFIGURATION);

export const getRemindManuallyReport = (date) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (date) {
				const result = await API.graphql(graphqlOperation(scheduleItemsReportQuery, { date, remindManually: true }));
				
				let data = null;
				if (result.data && result.data.scheduleItemsReport) data = result.data.scheduleItemsReport;
				else throw new Error('Failed to get report data');
				
				let dataToReturn = [];
				if (data.items) dataToReturn = [...data.items];
				else throw new Error('Failed to get report data');
								
				while (data.nextToken){
					
					const result = await API.graphql(graphqlOperation(scheduleItemsReportQuery, { date,remindManually:true, nextToken:data.nextToken }));
					if (result.data && result.data.scheduleItemsReport) data = result.data.scheduleItemsReport;					
					else throw new Error('Failed to get report data');
					
					if (data.items) 
						dataToReturn = [...dataToReturn, ...data.items];
					else throw new Error('Failed to get report data');
				}

				if (dataToReturn) return resolve(dataToReturn);
				else throw new Error('Failed to get report data');
			}
		} catch (e) {
			if (e instanceof Error) return reject(e);
			else return reject(new Error('Failed to get report data'));
		}
	});

}

export const getVisitingDoctorsReport = (date) => {
	return new Promise(async (resolve, reject) => {
		try {
			if (date) {
				const result = await API.graphql(graphqlOperation(visitingDoctorsReportQuery, { date }));
				
				let data = null;
				if (result.data && result.data.scheduleItemsReport) data = result.data.scheduleItemsReport;
				else throw new Error('Failed to get report data');
				
				let dataToReturn = [];
				if (data.items) dataToReturn = [...data.items];
				else throw new Error('Failed to get report data');
								
				while (data.nextToken){
					
					const result = await API.graphql(graphqlOperation(visitingDoctorsReportQuery, { date,nextToken:data.nextToken }));
					if (result.data && result.data.scheduleItemsReport) data = result.data.scheduleItemsReport;					
					else throw new Error('Failed to get report data');

					if (data.items) 
						dataToReturn = [...dataToReturn, ...data.items];
					else throw new Error('Failed to get report data');
				}

				if (dataToReturn) return resolve(dataToReturn);
				else throw new Error('Failed to get report data');
			}
		} catch (e) {
			if (e instanceof Error) return reject(e);
			else return reject(new Error('Failed to get report data'));
		}
	});

}
export const getReportDoctors = () => {
	return new Promise(async (resolve, reject) => {
		try {
			const { data } = await API.graphql(graphqlOperation(getReportDoctorsQuery, { isFavouriteOnly: false }));

			if (data && data.listDoctors && data.listDoctors.items) return resolve(data.listDoctors.items);
			else throw new Error('Failed to get report doctors');
		} catch (e) {
			if (e instanceof Error) return reject(e);
			else return reject(new Error('Failed to get report doctors'));
		}
	});

}