import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Settings extends Component {
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Settings</h1>
				</header>
				<div className="settings__container">
				{
						window.localStorage.getItem('userRights') === "admin" ?
					
					<div className="settings__item">
						<Link className="settings__item--link inherit-all" to="/settings/doctors">
							<FontAwesomeIcon className="settings__item--icon" icon="stethoscope" /><br />
							<p className="settings__item--text">Doctor List</p>
						</Link>
					</div>
					:
					null
				}
				{
					window.localStorage.getItem('userRights') === "admin" || window.localStorage.getItem('userRights') === "limitedadmin" ?
						<div className="settings__item">
							<Link className="settings__item--link inherit-all" to="/settings/reports">
								<FontAwesomeIcon className="settings__item--icon" icon="file-pdf" /><br />
								<p className="settings__item--text">Reports</p>
							</Link>
						</div>
					:
					null
				}				
				</div>
			</div>
		);
	}
}

export default Settings;
