export default `
	query getScheduleItem($docId:ID!, $creationTimestamp:AWSDateTime!){
			getScheduleItem(docId:$docId,creationTimestamp:$creationTimestamp){
					docId
					creationTimestamp
					itemId
					fromTimestamp
					toTimestamp
					clinic
					doctorRemarks
					pharmacyRemarks
					type
					reminders{
						email{
							lastSentTimestamp
							lastStatus
						}
						sms{
							lastSentTimestamp
							lastStatus
						}
					}
					patient{
						name{
							firstName
							lastName
						}
						email
						mobile{
							countryPrefixCode
							mobileNumber
						}
						landline{
							countryPrefixCode
							landlineNumber
						}
						idCard
						dob
						remindManually
					}
					billing{
						doctorRate
						clinicFee
						toBePaidAt
						services{
								title
								price
								currency
								bought
						}
					}
				}
			}
		`