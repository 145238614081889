import Amplify, { API, graphqlOperation } from "aws-amplify";
import { AWS_CONFIGURATION, PUBLIC_HOLIDAYS } from './../config';
import listDoctorsNames from './../queries/listDoctorsNames';
import listTodos from './../queries/listTodos';
import { nullifyPatient, formatRequest } from './../libs/general';
import access from 'safe-access';
import moment from 'moment';

import listAppointmentsQuery from './../queries/listAppointments';
import listPendingQuery from './../queries/listPending';
import searchAppointmentsQuery from './../queries/searchAppointments';
import getAppointmentQuery from './../queries/getAppointment';

import createAppointmentMutation from './../mutations/createAppointment';
import deleteAppointmentMutation from './../mutations/deleteAppointment';
import splitAppointmentMutation from './../mutations/splitAppointment';
import updateAppointmentMutation from './../mutations/updateAppointment';

import getDoctorQuery from './../queries/getDoctor';
import { getCurrentUserId, getCurrentUserRights } from './../libs/auth';

Amplify.configure(AWS_CONFIGURATION);
API.configure(AWS_CONFIGURATION);

export const getInitialInfo = async () => {
	try {
		const rights = await getCurrentUserRights();
		const docId = await getCurrentUserId();

		if (rights === "doctor") {
			const tempDoc = await API.graphql(graphqlOperation(getDoctorQuery, { docId }));
			const doc = tempDoc.data.getDoctor.doctor;
			return {
				alphabeticalList: [doc],
				favouritesList: [doc],
				todosList: []
			}

		} else if (rights === "admin" || rights === "limitedadmin") {
			const alphabeticalList = await API.graphql(graphqlOperation(listDoctorsNames, { isFavouriteOnly: false }));
			const favouritesList = await API.graphql(graphqlOperation(listDoctorsNames, { isFavouriteOnly: true }));
			const todosList = await API.graphql(graphqlOperation(listTodos));

			return {
				alphabeticalList: alphabeticalList.data.listDoctors.items,
				favouritesList: favouritesList.data.listDoctors.items,
				todosList: todosList.data.listTodos.items
			};
		}
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get initial data');
	}
}

export const getAppointments = async (docId, retrievePast) => {
	try {
		if (docId) {
			const appointmentList = await API.graphql(graphqlOperation(listAppointmentsQuery, { docId, retrievePast }));
			return filterAppointments(appointmentList.data.listScheduleItems.items);
		}
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get appointment list');
	}
}

export const listPending = async (docId) => {
	try {
		const pendingList = await API.graphql(graphqlOperation(listPendingQuery, { docId }));
		return pendingList.data.getPendingList.items;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get pending list');
	}
}

export const getAppointment = async (docId, creationTimestamp) => {
	try {
		const appointment = await API.graphql(graphqlOperation(getAppointmentQuery, { docId, creationTimestamp }));
		return appointment.data.getScheduleItem;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get appointment');
	}
}

export const createAppointment = async (docId, params) => {
	try {
		const tempParams = Object.assign({}, nullifyPatient(params));
		if (access(tempParams, 'patient.mobile') && !access(tempParams,'patient.mobile.countryPrefixCode')) tempParams.patient.mobile.countryPrefixCode = 356;
		if (access(tempParams, 'patient.landline')) tempParams.patient.landline.countryPrefixCode = 356;
		if (tempParams.smsReminder) delete tempParams.smsReminder;
		if (tempParams.emailReminder) delete tempParams.emailReminder;
		if (tempParams.docId) delete tempParams.docId;
		if (tempParams.creationTimestamp) delete tempParams.creationTimestamp;
		if (tempParams.reminders || tempParams.reminders === null) delete tempParams.reminders;
		if (!tempParams.pharmacyRemarks) tempParams.pharmacyRemarks = null;
		if (!tempParams.doctorRemarks) tempParams.doctorRemarks = null;
		if (tempParams.fromTimestamp) tempParams.fromTimestamp = moment.utc(tempParams.fromTimestamp).format();
		if (tempParams.toTimestamp) tempParams.toTimestamp = moment.utc(tempParams.toTimestamp).format();

		let createBoth = false;
		if (tempParams.type === 'both'){
			createBoth = true;
			tempParams.type = 'available';
		} 

		const item = await API.graphql(graphqlOperation(createAppointmentMutation, {
			docId: formatRequest(docId),
			item: formatRequest(tempParams)
		}));

		if (item && item.errors && item.errors instanceof Array && item.errors.length >= 1)
			return new Error(item.errors);
		else if (item && item.data && item.data.createScheduleItem){
			if (createBoth === true){
				tempParams.type = 'pending';
				await API.graphql(graphqlOperation(createAppointmentMutation, {
					docId: formatRequest(docId),
					item: formatRequest(tempParams)
				}));
			}
			
			return item.data.createScheduleItem;
		}			
		else return new Error('Failed to create appointment');
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to create appointment');
	}
}

export const updateAppointment = async (docId, creationTimestamp, params) => {
	try {
		const tempParams = Object.assign({}, nullifyPatient(params));
		if (params && params.docId) delete tempParams.docId;
		if (params && params.smsReminder) delete tempParams.smsReminder;
		if (params && params.emailReminder) delete tempParams.emailReminder;
		if (params && params.creationTimestamp) delete tempParams.creationTimestamp;
		if (params && (params.reminders || tempParams.reminders === null)) delete tempParams.reminders;
		if (!access(tempParams, 'patient.mobile.countryPrefixCode')) tempParams.patient.mobile.countryPrefixCode = 356;
		if (access(tempParams, 'patient.landline.countryPrefixCode')) tempParams.patient.landline.countryPrefixCode = 356;
		if (!tempParams.pharmacyRemarks) tempParams.pharmacyRemarks = null;
		if (!tempParams.doctorRemarks) tempParams.doctorRemarks = null;
		if (tempParams.fromTimestamp) tempParams.fromTimestamp = moment.utc(tempParams.fromTimestamp).format();
		if (tempParams.toTimestamp) tempParams.toTimestamp = moment.utc(tempParams.toTimestamp).format();

		const item = await API.graphql(graphqlOperation(updateAppointmentMutation,
			{
				docId: formatRequest(docId),
				creationTimestamp: formatRequest(creationTimestamp),
				item: formatRequest(tempParams)
			}));

		if (item && item.errors && item.errors instanceof Array && item.errors.length >= 1)
			return new Error(item.errors);
		else if (item && item.data && item.data.updateScheduleItem)
			return item.data.updateScheduleItem;
		else return new Error('Failed to update appointment');
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to update appointment');
	}
}

export const splitAppointment = async (docId, creationTimestamp)
	=> await API.graphql(graphqlOperation(splitAppointmentMutation, { docId, creationTimestamp }));

export const deleteAppointment = async (docId, creationTimestamp)
	=> await API.graphql(graphqlOperation(deleteAppointmentMutation, { docId, creationTimestamp }));

export const searchAppointments = async (docId, query) => {
	try {
		const searchResult = await API.graphql(graphqlOperation(searchAppointmentsQuery, {
			docId: formatRequest(docId),
			query: formatRequest(query)
		}));
		return searchResult.data.searchScheduleItems.items;
	} catch (e) {
		if (e instanceof Error) return e;
		else return new Error('Failed to get search results');
	}
}

const filterAppointments = (appointments) => {

	// Speed needs to be improved for this filter - around 10s for Dr. Borg
	const filteredAppointments = appointments.filter((app) => {
		for (const app2 of appointments) {
			if (moment(app.fromTimestamp).isSame(moment(app2.fromTimestamp)) && (app.type !== 'filled') && (app2.type === 'filled'))
				return false;
		}
		return true;
	});

	const autoAppointments = getAutoAppointments(filteredAppointments);

	return [...autoAppointments, ...filteredAppointments];
};

const getAutoAppointments = (dbApps) => {
	let autoSchedule = [];
	
	try {
		autoSchedule = JSON.parse(window.localStorage.getItem('currentDoctorSchedule'));
	} catch (e) {
		return [];
	}
	const toReturn = [];

	for (const s of autoSchedule) {
		let lastDay;

		switch (s.type) {
			case 'daily':
				lastDay = moment().add(2, 'week');
				break;
			case 'weekly':
				lastDay = moment().add(6, 'month');
				break;
			case 'every2weeks':
			case 'every3weeks':
				lastDay = moment().add(1, 'year');
				break;
			case 'monthly':
				lastDay = moment().add(2, 'year');
				break;
			case 'every2months':
				lastDay = moment().add(4, 'year');
				break;
			case 'every6months':
				lastDay = moment().add(10, 'year');
				break;
			case 'yearly':
				lastDay = moment().add(20, 'year');
				break;
			default:
				lastDay = moment().add(1, 'year');
				break;
		}

		const startDate = moment(s.startDate);
		const currentDay = moment(s.startDate);
		let alternateDone = true;
		let threeWeekCounter = 1;
		let sixMonthsFlag = moment(s.startDate); // DOES NOT CATER FOR SATURDAYS & SUNDAYS --- NEEDS TO BE FIXED
		let appPointer = 0;

		while (currentDay.isSameOrBefore(lastDay, 'day')) {

			let isPublicHoliday = false;
			for (const ph of PUBLIC_HOLIDAYS) {
				let holiday;
				
				if (ph.isDynamic && ph.isDynamic===true) 
					holiday = moment(ph.date[currentDay.year().toString()]).year(currentDay.year());
				else holiday = moment(ph.date).year(currentDay.year());
				
				if (holiday && currentDay.isSame(holiday, 'day')) {
					isPublicHoliday = true;
					break;
				}
			}

			if ((s.type === 'every2weeks' && currentDay.weekday() === startDate.weekday()) // reset alternateDone flag if applicable
				|| (s.type === 'every2months' && currentDay.date() === startDate.date())) {
				alternateDone = !alternateDone;
			}

			if (s.type === 'every3weeks' && currentDay.weekday() === startDate.weekday()) {
				if (threeWeekCounter === 2) threeWeekCounter = 0;
				else threeWeekCounter++;
			}

			if (currentDay.isSameOrAfter(moment(), 'day') && (
				   (s.type === 'daily' && currentDay.weekday()!==6)
				|| (s.type === 'weekly' && currentDay.weekday() === startDate.weekday())
				|| (s.type === 'monthly' && currentDay.date() === startDate.date())
				|| (s.type === 'yearly' && currentDay.date() === startDate.date() && currentDay.month() === startDate.month())
				|| (s.type === 'every2weeks' && currentDay.weekday() === startDate.weekday() && alternateDone === false)
				|| (s.type === 'every3weeks' && currentDay.weekday() === startDate.weekday() && threeWeekCounter === 2)
				|| (s.type === 'every2months' && currentDay.date() === startDate.date() && alternateDone === false)
				|| (s.type === 'every6months' && currentDay.isSame(sixMonthsFlag)))) {

				if (s.type === 'every6months') sixMonthsFlag.add(6, 'months');

				const startTime = moment(currentDay).set({
					hours: s.startHour,
					minutes: s.startMinute,
					seconds: 0,
					milliseconds: 0
				});
				const startTime2 = moment(currentDay).set({ //to avoid using startTime reference
					hours: s.startHour,
					minutes: s.startMinute,
					seconds: 0,
					milliseconds: 0
				});
				const endTime = moment(currentDay).set({
					hours: s.endHour,
					minutes: s.endMinute,
					seconds: 0,
					milliseconds: 0
				});
				let currentTimeStart = startTime;
				let currentTimeEnd = startTime2;
				currentTimeEnd.add(s.minsPerAppointment, 'minute');

				// eslint-disable-next-line 
				Number.prototype.zeroPad = function () {
					return ('0' + this).slice(-2);
				};

				while (currentTimeStart.isBefore(endTime, 'minute')) {
					// check if app from db already exist for that fromTimestamp
					const newFromString = moment(`${currentDay.year()}-${(currentDay.month() + 1).zeroPad()}-${currentDay.date().zeroPad()}T${currentTimeStart.hour().zeroPad()}:${currentTimeStart.minute().zeroPad()}`).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
					const newFrom = moment(newFromString); 
					const newToString = moment(`${currentDay.year()}-${(currentDay.month() + 1).zeroPad()}-${currentDay.date().zeroPad()}T${currentTimeEnd.hour().zeroPad()}:${currentTimeEnd.minute().zeroPad()}`).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
					let noReturn = false;

					do{
						if (dbApps[appPointer]){
							const dbFrom = moment.utc(dbApps[appPointer].fromTimestamp);
							if (dbFrom.isSame(newFrom,'minute')){
								
									noReturn = true; // skip auto app if dbApp for same exact minute exists
									break; // stop execution of do..while since auto app will be skipped
								
							}else
								noReturn = false; // don't skip auto app

							if (dbApps[appPointer + 1] 
								&& moment.utc(dbApps[appPointer + 1].fromTimestamp).isSameOrBefore(newFrom,'minute')) 
									appPointer++; // get next dbApp
							else break;
						}else{
							break;
						}
					}while (moment.utc(dbApps[appPointer].fromTimestamp).isSameOrBefore(newFrom,'minute')); // keep going till dbApp is not before auto app so they can never be the same going forward
					
					if (!noReturn){
						toReturn.push({
							docId: window.localStorage.getItem('currentDoctorId'),
							creationTimestamp: moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z',
							fromTimestamp: newFromString,
							toTimestamp: newToString,
							type: isPublicHoliday ? 'holiday_blocked' : 'auto_available',
							patient: null,
							clinic: null,
							pharmacyRemarks: null,
							doctorRemarks: null,
							billing: null
						})
					}

					currentTimeStart.add(s.minsPerAppointment, 'minute');
					currentTimeEnd.add(s.minsPerAppointment, 'minute');
				}
			}

			currentDay.add(1, 'day');
		}
	}
	return toReturn;
}