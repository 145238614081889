import { Auth } from 'aws-amplify';

export const getCurrentUserRights = () => {
	return Auth.currentAuthenticatedUser()
		.then(user => {
			const groups = user.signInUserSession.idToken.payload["cognito:groups"];
			window.localStorage.setItem("userRights", groups[0].toString().toLowerCase());
			return groups[0].toString().toLowerCase();
		})
		.catch(err => console.log(err));
}

export const getCurrentUserId = () => {
	return Auth.currentAuthenticatedUser()
		.then(user => {
			const id = user.attributes.sub;
			window.localStorage.setItem("userId", id.toString().toLowerCase());
			return id.toString().toLowerCase();
		})
		.catch(err => console.log(err));
}


export const logout = () => {
	Auth.signOut()
		.then(() => window.localStorage.clear())
		.catch(err => console.log(err));
};