export default `
	query getPendingList($docId:ID!){
			getPendingList(docId:$docId){
				nextToken
				items{
					docId
					creationTimestamp
					itemId
					fromTimestamp
					toTimestamp
					pharmacyRemarks
					patient{
						name{
							firstName
							lastName
						}
						idCard
					}					
				}
			}
		}
	`