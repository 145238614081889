import React, { Component } from 'react';
import { Row, Col, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';

class Todos extends Component {

	render() {
		return (
			<div>
				{
					this.props.list && this.props.list.length > 0 ? this.props.list.map((todo) => {
						return (
							todo.isUpdateMode ?
								<div className="todo__list--item" key={todo.todoId}>
									<Row>
										<Col xs={10} sm={10} md={10} lg={10}>
											<FormControl
												componentClass="textarea"
												value={todo.tempBody}
												onChange={(e) => this.props.updateValue(todo.todoId, e.target.value)}
											>
											</FormControl>
											<h5 className="todo__list--subtext">{todo.createdBy} - {todo.createdOn}</h5>
										</Col>
										<Col xs={2} sm={2} md={2} lg={2}>
											<div className="settings__list--actionIcons">
												<FontAwesomeIcon onClick={() => this.props.handleUpdate(todo.todoId)} className="settings__list--update" icon="save" />
												<FontAwesomeIcon onClick={() => this.props.setUpdateMode(todo.todoId, false)} className="settings__list--delete" icon="ban" />
											</div>
										</Col>
									</Row>
								</div>
								:
								<div className="todo__list--item" key={todo.todoId} onClick={() => this.props.setUpdateMode(todo.todoId, true)}>
									<Row>
										<Col xs={10} sm={10} md={10} lg={10}>
											<h4 className="todo__list--text">{todo.body}</h4>
											<h5 className="todo__list--subtext">{todo.createdBy} - {moment(todo.createdOn).format('DD MMM YYYY - hh:mm a')}</h5>
										</Col>
										<Col xs={2} sm={2} md={2} lg={2}>
											<div className="settings__list--actionIcons">
												<FontAwesomeIcon onClick={() => this.props.setUpdateMode(todo.todoId, true)} className="settings__list--update" icon="pencil-alt" />
												<FontAwesomeIcon onClick={(e) => this.props.handleDelete(e, todo.todoId)} className="settings__list--delete" icon="trash-alt" />
											</div>
										</Col>
									</Row>
								</div>
						)
					})
						: <div className="settings__list--no-tasks-container">
							<p className="settings__list--no-tasks-text">No tasks found. Click the 'Create Task Button' to add one.</p>
						</div>
				}
			</div>
		);
	}
}

export default Todos;
