import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class ReportsList extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Report List</h1>
				</header>
				<div className="reports__list--container">
					<Link className="reports__list--item" to="/settings/reports/1">
						<p className="reports__list--title">Remind Manually Report</p>
						<p className="reports__list--description">Report shows the patients that have appointments on a particular day and need to be reminded manually.</p>
					</Link>
					<Link className="reports__list--item" to="/settings/reports/2">
						<p className="reports__list--title">Visiting Doctors Report</p>
						<p className="reports__list--description">Report shows the doctors that will be visiting on a particular day.</p>
					</Link>
				</div>
				<div className="large-push"></div>
			</div>
		);
	}
}

export default ReportsList;