export const AWS_CONFIGURATION = {
	Auth: {

		// REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
		identityPoolId: 'eu-west-1:10db65a5-1423-4a3b-9dff-23c1d4b64204',

		// REQUIRED - Amazon Cognito Region
		region: 'eu-west-1',

		// OPTIONAL - Amazon Cognito Federated Identity Pool Region 
		// Required only if it's different from Amazon Cognito Region
		// identityPoolRegion: 'XX-XXXX-X',

		// OPTIONAL - Amazon Cognito User Pool ID
		userPoolId: 'eu-west-1_r57ZZxOuN',

		// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
		userPoolWebClientId: '3gf6i8r175dfmnqlaf3q3ibnvh',

		// OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
		mandatorySignIn: true,

		// OPTIONAL - Configuration for cookie storage
		// cookieStorage: {
		// 	// REQUIRED - Cookie domain (only required if cookieStorage is provided)
		// 	domain: '.yourdomain.com',
		// 	// OPTIONAL - Cookie path
		// 	path: '/',
		// 	// OPTIONAL - Cookie expiration in days
		// 	expires: 365,
		// 	// OPTIONAL - Cookie secure flag
		// 	secure: true
		// },

		// OPTIONAL - customized storage object
		// storage: new MyStorage(),

		// OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
		// authenticationFlowType: 'USER_PASSWORD_AUTH'
	},
	"aws_appsync_graphqlEndpoint": "https://z7hqwvtpfjcg5bebnse76umbay.appsync-api.eu-west-1.amazonaws.com/graphql",
	"aws_appsync_region": "eu-west-1",
	"aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
	"aws_appsync_apiKey": null
}

export const AWS_RO_CONFIGURATION = {
	"aws_appsync_graphqlEndpoint": "https://t2o3hdc4hzgndd24urkwg2v3ye.appsync-api.eu-west-1.amazonaws.com/graphql",
	"aws_appsync_region": "eu-west-1",
	"aws_appsync_authenticationType": "API_KEY",
	"aws_appsync_apiKey": "da2-hltrpbibhrb67lobw4rl4t7qku"
}

export const PUBLIC_HOLIDAYS = [
	{
		name: "new year",
		isDynamic: false,
		date: "2017-01-01"
	},
	{
		name: "st. paul",
		isDynamic: false,
		date: "2017-02-10"
	},
	{
		name: "st. joseph",
		isDynamic: false,
		date: "2017-03-19"
	},
	{
		name: "good friday",
		isDynamic: true,
		date: {
			"2018": "2017-03-30",
			"2019": "2017-04-19",
			"2020": "2017-04-10",
			"2021": "2017-04-02",
			"2022": "2017-04-15",
			"2023": "2017-04-07",
			"2024": "2017-03-29",
			"2025": "2017-04-18",
			"2026": "2017-04-03",
			"2027": "2017-03-26",
			"2028": "2017-04-14",
			"2029": "2017-03-30",
			"2030": "2017-04-19"
		}
	},
	{
		name: "freedom day",
		isDynamic: false,
		date: "2017-03-31"
	},
	{
		name: "worker's day",
		isDynamic: false,
		date: "2017-05-01"
	},
	{
		name: "sette giugno",
		isDynamic: false,
		date: "2017-06-07"
	},
	{
		name: "st. peter & st. paul",
		isDynamic: false,
		date: "2017-06-29"
	},
	{
		name: "assumption",
		isDynamic: false,
		date: "2017-08-15"
	},
	{
		name: "victory day",
		isDynamic: false,
		date: "2017-09-08"
	},
	{
		name: "independence day",
		isDynamic: false,
		date: "2017-09-21"
	},
	{
		name: "immaculate conception",
		isDynamic: false,
		date: "2017-12-08"
	},
	{
		name: "republic day",
		isDynamic: false,
		date: "2017-12-13"
	},
	{
		name: "christmas",
		isDynamic: false,
		date: "2017-12-25"
	}
]

export const countryCodeToRegionCodeMap = {
1:["US","AG","AI","AS","BB","BM","BS","CA","DM","DO","GD","GU","JM","KN","KY","LC","MP","MS","PR","SX","TC","TT","VC","VG","VI"]
,7:["RU","KZ"]
,20:["EG"]
,27:["ZA"]
,30:["GR"]
,31:["NL"]
,32:["BE"]
,33:["FR"]
,34:["ES"]
,36:["HU"]
,39:["IT","VA"]
,40:["RO"]
,41:["CH"]
,43:["AT"]
,44:["GB","GG","IM","JE"]
,45:["DK"]
,46:["SE"]
,47:["NO","SJ"]
,48:["PL"]
,49:["DE"]
,51:["PE"]
,52:["MX"]
,53:["CU"]
,54:["AR"]
,55:["BR"]
,56:["CL"]
,57:["CO"]
,58:["VE"]
,60:["MY"]
,61:["AU","CC","CX"]
,62:["ID"]
,63:["PH"]
,64:["NZ"]
,65:["SG"]
,66:["TH"]
,81:["JP"]
,82:["KR"]
,84:["VN"]
,86:["CN"]
,90:["TR"]
,91:["IN"]
,92:["PK"]
,93:["AF"]
,94:["LK"]
,95:["MM"]
,98:["IR"]
,211:["SS"]
,212:["MA","EH"]
,213:["DZ"]
,216:["TN"]
,218:["LY"]
,220:["GM"]
,221:["SN"]
,222:["MR"]
,223:["ML"]
,224:["GN"]
,225:["CI"]
,226:["BF"]
,227:["NE"]
,228:["TG"]
,229:["BJ"]
,230:["MU"]
,231:["LR"]
,232:["SL"]
,233:["GH"]
,234:["NG"]
,235:["TD"]
,236:["CF"]
,237:["CM"]
,238:["CV"]
,239:["ST"]
,240:["GQ"]
,241:["GA"]
,242:["CG"]
,243:["CD"]
,244:["AO"]
,245:["GW"]
,246:["IO"]
,247:["AC"]
,248:["SC"]
,249:["SD"]
,250:["RW"]
,251:["ET"]
,252:["SO"]
,253:["DJ"]
,254:["KE"]
,255:["TZ"]
,256:["UG"]
,257:["BI"]
,258:["MZ"]
,260:["ZM"]
,261:["MG"]
,262:["RE","YT"]
,263:["ZW"]
,264:["NA"]
,265:["MW"]
,266:["LS"]
,267:["BW"]
,268:["SZ"]
,269:["KM"]
,290:["SH","TA"]
,291:["ER"]
,297:["AW"]
,298:["FO"]
,299:["GL"]
,350:["GI"]
,351:["PT"]
,352:["LU"]
,353:["IE"]
,354:["IS"]
,355:["AL"]
,356:["MT"]
,357:["CY"]
,358:["FI","AX"]
,359:["BG"]
,370:["LT"]
,371:["LV"]
,372:["EE"]
,373:["MD"]
,374:["AM"]
,375:["BY"]
,376:["AD"]
,377:["MC"]
,378:["SM"]
,380:["UA"]
,381:["RS"]
,382:["ME"]
,383:["XK"]
,385:["HR"]
,386:["SI"]
,387:["BA"]
,389:["MK"]
,420:["CZ"]
,421:["SK"]
,423:["LI"]
,500:["FK"]
,501:["BZ"]
,502:["GT"]
,503:["SV"]
,504:["HN"]
,505:["NI"]
,506:["CR"]
,507:["PA"]
,508:["PM"]
,509:["HT"]
,590:["GP","BL","MF"]
,591:["BO"]
,592:["GY"]
,593:["EC"]
,594:["GF"]
,595:["PY"]
,596:["MQ"]
,597:["SR"]
,598:["UY"]
,599:["CW","BQ"]
,670:["TL"]
,672:["NF"]
,673:["BN"]
,674:["NR"]
,675:["PG"]
,676:["TO"]
,677:["SB"]
,678:["VU"]
,679:["FJ"]
,680:["PW"]
,681:["WF"]
,682:["CK"]
,683:["NU"]
,685:["WS"]
,686:["KI"]
,687:["NC"]
,688:["TV"]
,689:["PF"]
,690:["TK"]
,691:["FM"]
,692:["MH"]
,800:["001"]
,808:["001"]
,850:["KP"]
,852:["HK"]
,853:["MO"]
,855:["KH"]
,856:["LA"]
,870:["001"]
,878:["001"]
,880:["BD"]
,881:["001"]
,882:["001"]
,883:["001"]
,886:["TW"]
,888:["001"]
,960:["MV"]
,961:["LB"]
,962:["JO"]
,963:["SY"]
,964:["IQ"]
,965:["KW"]
,966:["SA"]
,967:["YE"]
,968:["OM"]
,970:["PS"]
,971:["AE"]
,972:["IL"]
,973:["BH"]
,974:["QA"]
,975:["BT"]
,976:["MN"]
,977:["NP"]
,979:["001"]
,992:["TJ"]
,993:["TM"]
,994:["AZ"]
,995:["GE"]
,996:["KG"]
,998:["UZ"]
};