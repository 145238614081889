import React, { Component } from 'react';
import { updateDoctor } from '../../logic/settings';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, FormControl, ControlLabel, Row, Col, Grid } from 'react-bootstrap';
import { clearStorage } from './../../libs/general';

class DoctorSchedule extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doc: null,
			error: ''
		};
		this.handleSave = this.handleSave.bind(this);
	}
	componentWillMount() {
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.doc) {
			const { doc } = this.props.location.state;
			this.setState({ doc },
				() => {
					if (!(this.state.doc.preferences && this.state.doc.preferences.schedule
						&& this.state.doc.preferences.schedule instanceof Array
						&& this.state.doc.preferences.schedule.length >= 1)) {
						this.addSchedule();
					}
				}
			);
		}
	}
	handleChange = (e, i) => {
		if (e && e.target && e.target.id && e.target.value) {
			const field = e.target.id;
			const value = e.target.value;

			const doc = this.state.doc;
			doc.preferences.schedule[i][field] = value;
			this.setState({ doc });
		} else return;
	}
	handleSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const doc = await updateDoctor(this.state.doc);
		clearStorage();

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	addSchedule = () => {
		const doc = this.state.doc;

		if (!doc.preferences) doc.preferences = {}
		if (!doc.preferences.schedule) doc.preferences.schedule = []

		doc.preferences.schedule.push({
			type: '',
			startDate: null,
			minsPerAppointment: '',
			startHour: '',
			startMinute: '',
			endHour: '',
			endMinute: ''
		});
		this.setState({ doc });
	}
	deleteSchedule = (e, index) => {
		if (window.confirm('Are you sure you want to delete this schedule?')) {
			e.preventDefault();
			e.stopPropagation();

			const doc = this.state.doc;

			if (!(doc.preferences || doc.preferences.schedule
				|| doc.preferences.schedule instanceof Array
				|| doc.preferences.schedule[index]))
				return;

			doc.preferences.schedule.splice(index, 1);
			this.setState({ doc });
		}
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/doctors">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Doctor's Schedule</h1>
				</header>
				<Grid>
					<form autoComplete='on' onSubmit={this.handleSave}>
						{
							(this.state.doc.preferences && this.state.doc.preferences.schedule
								&& this.state.doc.preferences.schedule instanceof Array
								&& this.state.doc.preferences.schedule.length >= 1) ?
								this.state.doc.preferences.schedule.map((sched, i) => {
									return (
										<fieldset className="pages__form--fieldset" key={i}>
											<Row>
												<Col xsOffset={3} smOffset={3} mdOffset={3} lgOffset={3} xs={6} sm={6} md={3} lg={3}>
													<FormGroup>
														<ControlLabel>Type:</ControlLabel>
														<FormControl
															componentClass="select"
															id='type'
															required
															value={sched.type}
															onChange={(e) => this.handleChange(e, i)}>
															<option value="">--</option>
															<option value="daily">Daily</option>
															<option value="weekly">Weekly</option>
															<option value="every2weeks">Every 2 Weeks</option>
															<option value="every3weeks">Every 3 Weeks</option>
															<option value="monthly">Monthly</option>
															<option value="every2months">Every 2 Months</option>
															<option value="every6months">Every 6 Months</option>
															<option value='yearly'>Yearly</option>
															<option value="disabled">Disabled</option>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Start Date</ControlLabel>
														<FormControl
															id='startDate'
															defaultValue={sched.startDate}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='date'>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Appointment Length&nbsp;<small>(5 - 120 mins)</small></ControlLabel>
														<FormControl
															id='minsPerAppointment'
															defaultValue={sched.minsPerAppointment}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min='5'
															max='120'
															step='5'>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xsOffset={3} smOffset={3} mdOffset={0} lgOffset={0} xs={6} sm={6} md={3} lg={3}>
													<FormGroup>
														<ControlLabel>Start Hour&nbsp;<small>(0 - 23)</small></ControlLabel>
														<FormControl
															id='startHour'
															defaultValue={sched.startHour}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min='0'
															max='23'>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>Start Min&nbsp;<small>(0 - 59)</small></ControlLabel>
														<FormControl
															id='startMinute'
															defaultValue={sched.startMinute}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min='0'
															max='59'>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>End Hour&nbsp;<small>(0 - 23)</small></ControlLabel>
														<FormControl
															id='endHour'
															defaultValue={sched.endHour}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min='0'
															max='23'>
														</FormControl>
													</FormGroup>
													<FormGroup>
														<ControlLabel>End Minute&nbsp;<small>(0 - 59)</small></ControlLabel>
														<FormControl
															id='endMinute'
															defaultValue={sched.endMinute}
															required
															onChange={(e) => this.handleChange(e, i)}
															type='number'
															min='0'
															max='59'>
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
											<Row>
												<Col xsOffset={3} smOffset={3} mdOffset={3} lgOffset={3} xs={6} sm={6} md={6} lg={6}
													className={
														this.state.doc.preferences.schedule.length - 1 === i ?
															"pages__form--col settings__list--actionIcons right-text no-border"
															: "pages__form--col settings__list--actionIcons right-text"
													}>
													<FontAwesomeIcon className="settings__list--delete " onClick={(e) => this.deleteSchedule(e, i)} icon="trash-alt"></FontAwesomeIcon>
												</Col>
											</Row>
										</fieldset>

									)
								})
								: undefined
						}
						<div className="pages__action-bar--container">
							<div onClick={this.addSchedule} className="pages__action-bar--item">
								<FontAwesomeIcon icon="plus" />
							</div>
						</div>
						<div className="pages__form--error centered-text">
							{
								this.state.error && this.state.error instanceof Array ?
									this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
									: <p>{this.state.error.message}</p>
							}
						</div>
						<Row>
							<Col>
								<input className="save-button center-block small-button" type='submit' value='Save' />
							</Col>
						</Row>
					</form>
				</Grid>
			</div>
		);
	}
}

export default withRouter(DoctorSchedule);