import React, { Component } from 'react';
import { updateDoctor } from '../../logic/settings';
import { withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormGroup, FormControl, ControlLabel, Row, Col, Grid } from 'react-bootstrap';
import access from 'safe-access';
import { clearStorage } from './../../libs/general';


class DoctorBilling extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doc: null,
			error: ''
		};
		this.addBilling = this.addBilling.bind(this);
		this.addService = this.addService.bind(this);
	}
	componentWillMount() {
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.doc) {
			const { doc } = this.props.location.state;
			this.setState({ doc },
				() => {
					if (!(access(this, "state.doc.preferences.billing.services")
						&& this.state.doc.preferences.billing.services instanceof Array
						&& this.state.doc.preferences.billing.services.length > 0)) {
						this.addBilling();
					}
				}
			);
		}
	}
	newServiceProperties = () => {
		return (
			{
				title: '',
				price: '',
				currency: 'eur',
				bought: false
			}
		)
	}
	handleChange = (e, i) => {
		if (e && e.target && e.target.id && e.target.value !== null) {
			const field = e.target.id;
			const value = e.target.value;

			const doc = this.state.doc;
			doc.preferences.billing.services[i][field] = value;
			this.setState({ doc });
		} else return;
	}
	handleSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const doc = await updateDoctor(this.state.doc);
		clearStorage();

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	addBilling = () => {
		const doc = this.state.doc;

		if (!doc.preferences) doc.preferences = {}
		if (!doc.preferences.billing) doc.preferences.billing = {
			doctorRate: '',
			clinicFee: '',
			toBePaidAt: '',
			services: [this.newServiceProperties()]
		}

		if (!doc.preferences.billing.services) doc.preferences.billing.services = [];
		if (doc.preferences.billing.services.length <= 0) doc.preferences.billing.services.push(this.newServiceProperties());

		this.setState({ doc });
	}
	addService = (e) => {
		e.preventDefault();

		const doc = this.state.doc;
		doc.preferences.billing.services.push(this.newServiceProperties());
		this.setState({ doc });
	}
	deleteService = (e, index) => {
		if (window.confirm('Are you sure you want to delete this service?')) {
			{
				e.preventDefault();

				const doc = this.state.doc;

				if (!(doc.preferences || doc.preferences.billing
					|| doc.preferences.billing
					|| doc.preferences.billing.services instanceof Array
					|| doc.preferences.billing.services[index]))
					return;

				doc.preferences.billing.services.splice(index, 1);
				this.setState({ doc });
			}
		}
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/doctors">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					<h1>Doctor's Billing</h1>
				</header>
				<div className="medium-push"></div>
				<Grid>
					<form autoComplete='on' onSubmit={this.handleSave}>
						<Row>
							<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={12} sm={12} md={4} lg={4}
								className="billing-settings__services--col">
								<h3 className="centered-text">Services</h3>
								{
									(access(this, "state.doc.preferences.billing.services")
										&& this.state.doc.preferences.billing.services instanceof Array
										&& this.state.doc.preferences.billing.services.length >= 1) ?
										this.state.doc.preferences.billing.services.map((service, i) => {
											return (
												<fieldset className="billing-settings__form--item" key={i}>
													<Col xs={9} sm={9} md={9} lg={9}>
														<Row>
															<FormGroup>
																<FormControl
																	id='title'
																	type='text'
																	placeholder="Title"
																	required
																	value={service.title}
																	onChange={(e) => this.handleChange(e, i)}>
																</FormControl>
															</FormGroup>
														</Row>
														<Row>
															<Col xs={6} sm={6} md={6} lg={6}>
																<FormGroup>
																	<FormControl
																		componentClass="select"
																		id='currency'
																		disabled
																		value='eur'
																		onChange={(e) => this.handleChange(e, i)}>
																		<option value='eur'>EUR</option>
																	</FormControl>
																</FormGroup>
															</Col>
															<Col xs={6} sm={6} md={6} lg={6}>
																<FormGroup>
																	<FormControl
																		id='price'
																		placeholder="Price"
																		type='number'
																		required
																		value={service.price}
																		onChange={(e) => this.handleChange(e, i)}>
																	</FormControl>
																</FormGroup>
															</Col>
														</Row>
													</Col>
													<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={1} sm={1} md={1} lg={1}
														className="pages__form--col settings__list--actionIcons right-text no-border">
														<FontAwesomeIcon className="settings__list--delete" onClick={(e) => this.deleteService(e, i)} icon="trash-alt"></FontAwesomeIcon>
													</Col>
												</fieldset>
											)
										})
										: undefined
								}
								<div className="pages__action-bar--container">
									<div onClick={this.addService} className="pages__action-bar--item">
										<FontAwesomeIcon icon="plus" />
									</div>
								</div>
							</Col>
							<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={4} sm={4} md={4} lg={4}>
								<FormGroup>
									<ControlLabel>Doctor Rate</ControlLabel>
									<FormControl
										id='doctorRate'
										type='number'
										required
										defaultValue={access(this, "state.doc.preferences.billing.doctorRate") ? this.state.doc.preferences.billing.doctorRate : null}
										onChange={(e) => this.setState({ doc: { ...this.state.doc, preferences: { ...this.state.doc.preferences, billing: { ...this.state.doc.preferences.billing, doctorRate: e.target.value } } } })}>
									</FormControl>
								</FormGroup>
								<FormGroup>
									<ControlLabel>Clinic Fee</ControlLabel>
									<FormControl
										id='clinicFee'
										type='number'
										required
										defaultValue={access(this, "state.doc.preferences.billing.clinicFee") ? this.state.doc.preferences.billing.clinicFee : null}
										onChange={(e) => this.setState({ doc: { ...this.state.doc, preferences: { ...this.state.doc.preferences, billing: { ...this.state.doc.preferences.billing, clinicFee: e.target.value } } } })}>
									</FormControl>
								</FormGroup>
								<FormGroup>
									<ControlLabel>To be paid at</ControlLabel>
									<FormControl
										componentClass="select"
										required
										id='toBePaidAt'
										defaultValue={access(this, "state.doc.preferences.billing.toBePaidAt") ? this.state.doc.preferences.billing.toBePaidAt : null}
										onChange={(e) => this.setState({ doc: { ...this.state.doc, preferences: { ...this.state.doc.preferences, billing: { ...this.state.doc.preferences.billing, toBePaidAt: e.target.value } } } })}>
										<option value="">--</option>
										<option value="pharmacycounter">Pharmacy Counter</option>
										<option value="doctorinclinic">Doctor In Clinic</option>
									</FormControl>
								</FormGroup>
								<FormGroup>
									<ControlLabel>Currency</ControlLabel>
									<FormControl
										componentClass="select"
										id='currency'
										value='eur'
										disabled>
										<option value='eur'>EUR</option>
									</FormControl>
								</FormGroup>
							</Col>
						</Row>
						<Row>
							<div className="medium-push"></div>
							<div className="pages__form--error centered-text">
								{
									this.state.error && this.state.error instanceof Array ?
										this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
										: <p>{this.state.error.message}</p>
								}
							</div>
							<div>
								<input className="save-button center-block small-button" type='submit' value='Save' />
							</div>
						</Row>
					</form>
				</Grid>
			</div>
		);
	}
}

export default withRouter(DoctorBilling);