import React, { Component } from 'react';
import { createDoctor, updateDoctor } from '../../logic/settings';
import { withRouter, Link } from 'react-router-dom';
import { FormGroup, FormControl, ControlLabel } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearStorage } from './../../libs/general';

class DoctorDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doc: {
				docId: '',
				title: '',
				name: {
					firstName: '',
					lastName: ''
				},
				mobile: {
					mobileNumber: ''
				},
				speciality: '',
				email: '',
				isFavourite: false,
				clinic: 'Clinic 1'
			},
			editMode: false,
			error: ''
		};
		this.handleCreate = this.handleCreate.bind(this);
	}
	componentWillMount() {

		if (this.props && this.props.location && this.props.location.state && this.props.location.state.doc) {
			const { doc } = this.props.location.state;
			this.setState({ doc, editMode: true });
		}
	}
	handleCreate = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const tempDoc = this.state.doc;
		tempDoc.isFavourite = this.state.doc.isFavourite === "true" ? true : false;

		const doc = await createDoctor(tempDoc);

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	handleUpdate = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const tempDoc = this.state.doc;
		tempDoc.isFavourite = this.state.doc.isFavourite === "true" ? true : false;

		const doc = await updateDoctor(tempDoc);
		clearStorage();

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	render() {
		let { editMode } = this.state;
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/doctors">Back</Link>
				</div>
				<div className="pages__modal--container">
					<header className="pages__header--container">
						<h1>{editMode === true ? 'Update Doctor' : 'New Doctor'}</h1>
					</header>
					<form className="pages__form" autoComplete='on' onSubmit={editMode === true ? this.handleUpdate : this.handleCreate}>
						<FormGroup className="pages__form--item">
							<ControlLabel>Title:</ControlLabel>
							<FormControl
								componentClass="select"
								placeholder='Title'
								id='title'
								required
								value={this.state.doc.title}
								onChange={(e) => this.setState({ doc: { ...this.state.doc, title: e.target.value } })}>
								<option value=''>--</option>
								<option value='dr'>Dr.</option>
								<option value='mr'>Mr.</option>
								<option value='ms'>Ms.</option>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>First Name:</ControlLabel>
							<FormControl
								id='firstName'
								value={this.state.doc.name.firstName}
								placeholder='e.g. John'
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, name: { ...this.state.doc.name, firstName: e.target.value } } })}
								type='text'>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Last Name:</ControlLabel>
							<FormControl
								id='lastName'
								value={this.state.doc.name.lastName}
								placeholder='e.g. Borg'
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, name: { ...this.state.doc.name, lastName: e.target.value } } })}
								type='text'>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Speciality:</ControlLabel>
							<FormControl
								id='speciality'
								value={this.state.doc.speciality}
								placeholder='e.g. Neurologist'
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, speciality: e.target.value } })}
								type='text'>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Email:</ControlLabel>
							<FormControl
								id='email'
								value={this.state.doc.email}
								placeholder='e.g. john.borg@gmail.com'
								onChange={(e) => this.setState({ doc: { ...this.state.doc, email: e.target.value } })}
								type='email'>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Mobile Number:</ControlLabel>
							<FormControl
								id='mobileNumber'
								value={String(this.state.doc.mobile.mobileNumber)}
								placeholder='e.g. 79797979'
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, mobile: { ...this.state.doc.mobile, mobileNumber: e.target.value } } })}
								type='number'>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Default clinic:</ControlLabel>
							<FormControl
								componentClass="select"
								id='clinic'
								value={this.state.doc.clinic}
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, clinic: e.target.value } })}>
								<option value="Clinic 1">Clinic 1</option>
								<option value="Clinic 2">Clinic 2</option>
								<option value="Clinic 3">Clinic 3</option>
								<option value="Clinic 4">Clinic 4</option>
							</FormControl>
						</FormGroup>
						<FormGroup className="pages__form--item">
							<ControlLabel>Add to favourites list:</ControlLabel>
							<FormControl
								componentClass="select"
								id='isFavourite'
								defaultValue={this.state.doc.isFavourite}
								required
								onChange={(e) => this.setState({ doc: { ...this.state.doc, isFavourite: e.target.value } })}>
								<option value="false">No</option>
								<option value="true">Yes</option>
							</FormControl>
						</FormGroup>
						<div className="pages__form--error">
							{
								this.state.error && this.state.error instanceof Array ?
									this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
									: <p>{this.state.error.message}</p>
							}
						</div>
						<input className="save-button" type='submit' value={editMode === true ? 'Update' : 'Create'} />
					</form>
				</div>
			</div>
		);
	}
}

export default withRouter(DoctorDetails);