import React, { Component } from 'react';
import { updateDoctor } from '../../logic/settings';
import { withRouter, Link } from 'react-router-dom';
import { FormGroup, FormControl, Grid, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clearStorage } from './../../libs/general';

class DoctorFields extends Component {
	constructor(props) {
		super(props);
		this.state = {
			doc: null,
			error: ''
		};
	}
	componentWillMount() {
		if (this.props && this.props.location && this.props.location.state && this.props.location.state.doc) {
			const { doc } = this.props.location.state;
			this.setState({ doc },
				() => {
					if (!(this.state.doc.preferences && this.state.doc.preferences.fields
						&& this.state.doc.preferences.fields instanceof Array
						&& this.state.doc.preferences.fields.length > 0)) {
						this.addFields();
					}
				}
			);
		}
	}
	handleChange = (e, i) => {
		if (e && e.target && e.target.id) {
			const field = e.target.id;
			const value = e.target.checked;

			const doc = this.state.doc;
			doc.preferences.fields[i][field] = value;
			this.setState({ doc });
		} else return;
	}
	handleSave = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		const doc = await updateDoctor(this.state.doc);
		clearStorage();

		if (doc instanceof Error)
			this.handleError(doc);
		else
			this.props.history.push("/settings/doctors");
	}
	handleError = (e) => {
		console.log(e);
		this.setState({ error: e });
	}
	addFields = () => {
		const doc = this.state.doc;

		if (!doc.preferences) doc.preferences = {}
		if (!doc.preferences.fields) doc.preferences.fields = []

		doc.preferences.fields.push(
			{
				fieldName: 'firstname',
				visible: false,
				required: false
			},
			{
				fieldName: 'lastname',
				visible: false,
				required: false
			},
			{
				fieldName: 'idcard',
				visible: false,
				required: false
			},
			{
				fieldName: 'mobilenumber',
				visible: false,
				required: false
			},
			{
				fieldName: 'landlinenumber',
				visible: false,
				required: false
			},
			{
				fieldName: 'email',
				visible: false,
				required: false
			},
			{
				fieldName: 'dob',
				visible: false,
				required: false
			});
		this.setState({ doc });
	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/doctors">Back</Link>
				</div>
				<div className="pages__modal--container">
					<header className="pages__header--container">
						<h1>Doctor's Fields</h1>
					</header>
					<Grid>
						<form className="pages__form" autoComplete='on' onSubmit={this.handleSave}>
							<Row className="pages__form--fieldset fields_settings__form--fieldset">
								<Col className="fields_settings__form--field-title-container"
									xsOffset={3} smOffset={3} mdOffset={3} lgOffset={3}
									xs={4} sm={4} md={4} lg={4}>
									<p className="field_settings__form--field-title">Visible</p>
								</Col>
								<Col className="fields_settings__form--field-title-container"
									xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1}
									xs={4} sm={4} md={4} lg={4}>
									<p className="field_settings__form--field-title">Required</p>
								</Col>
							</Row>
							{
								(this.state.doc.preferences && this.state.doc.preferences.fields
									&& this.state.doc.preferences.fields instanceof Array
									&& this.state.doc.preferences.fields.length >= 1) ?
									this.state.doc.preferences.fields.map((field, i) => {
										return (
											<Row className="pages__form--row fields_settings__form--row" key={i}>
												<Col xsOffset={2} smOffset={2} mdOffset={2} lgOffset={2}
													xs={3} sm={3} md={3} lg={3}>
													<p className="field_settings__form--field-name">{field.fieldName}</p>
												</Col>
												<Col xs={3} sm={3} md={3} lg={3}>
													<FormGroup className="pages__form--item">
														<FormControl
															id='visible'
															type='checkbox'
															checked={field.visible}
															onChange={(e) => this.handleChange(e, i)}>
														</FormControl>
													</FormGroup>
												</Col>
												<Col xs={3} sm={3} md={3} lg={3}>
													<FormGroup className="pages__form--item">
														<FormControl
															id='required'
															type='checkbox'
															checked={field.required}
															onChange={(e) => this.handleChange(e, i)}>
														</FormControl>
													</FormGroup>
												</Col>
											</Row>
										)
									})
									: undefined
							}
							<div className="pages__form--error">
								{
									this.state.error && this.state.error instanceof Array ?
										this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
										: <p>{this.state.error.message}</p>
								}
							</div>
							<input className="save-button" type='submit' value="Save" />
						</form>
					</Grid>
				</div>
			</div>
		);
	}
}

export default withRouter(DoctorFields);