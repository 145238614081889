import React, { Component } from 'react';
import { createTodo, createdTodoSubscription, deleteTodo, deletedTodoSubscription, updateTodo, updatedTodoSubscription } from './../../logic/todos';
import TodoList from './TodoList.jsx';
import NewTodo from './NewTodo.jsx';

class Todos extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: []
		}
		this.handleDelete = this.handleDelete.bind(this);
	}
	componentWillMount() {
		this.subscribeNewTodos();
		this.subscribeDeleteTodos();
		this.subscribeUpdatedTodos();
	}
	componentWillReceiveProps(newProps) {
		this.setState({ list: newProps.list });
	}
	subscriptionHandlerAdd = (todo) => {
		const list = this.state.list;
		list.push(todo);
		this.setState({ list });
	}
	subscribeNewTodos = async () => {
		await createdTodoSubscription(this.subscriptionHandlerAdd);
	}
	subscriptionHandlerDelete = (todoId) => {
		const list = this.state.list.filter((todo) => todo.todoId !== todoId);
		this.setState({ list });
	}
	subscribeDeleteTodos = async () => {
		await deletedTodoSubscription(this.subscriptionHandlerDelete);
	}
	subscriptionHandlerUpdate = (todo) => {
		const list = this.state.list;
		list.forEach((t, i) => t.todoId === todo.todoId ? list[i] = todo : null)
		this.setState({ list });
	}
	subscribeUpdatedTodos = async () => {
		await updatedTodoSubscription(this.subscriptionHandlerUpdate);
	}
	handleDelete = async (e, todoId) => {
		if (window.confirm('Are you sure you want to delete this task?')) {
			e.stopPropagation();
			try {
				await deleteTodo(todoId);
				const list = this.state.list.filter((todo) => todo.todoId !== todoId);
				this.setState({ list });
				this.props.handleTodoDelete(todoId);
			} catch (e) {
				console.log(e);
			}
		}
	}
	setUpdateMode = (todoId, isUpdateMode) => {
		const list = this.state.list;
		list.forEach((todo) => {
			if (todo.todoId === todoId) {
				todo.isUpdateMode = isUpdateMode;
				if (isUpdateMode) todo.tempBody = todo.body;
			}
		})
		this.setState({ list });
	}
	updateValue = (todoId, body) => {
		const list = this.state.list;
		list.forEach((todo) => todo.todoId === todoId ? todo.tempBody = body : null)
		this.setState({ list });
	}
	handleUpdate = async (todoId) => {
		try {
			const newBody = this.state.list.find((todo) => todo.todoId === todoId).tempBody;
			const item = await updateTodo(todoId, newBody);
			const list = this.state.list;
			list.forEach((todo, i) => todo.todoId === item.todoId ? list[i] = item : null);
			this.setState({ list });
		} catch (e) {
			console.log(e);
		}
	}
	render() {
		return (
			<div>
				{
					this.props.hidden === false ?
						<div>
							<header className="pages__header--container centered-text">
								<h1>Task List</h1>
							</header>
							<div>
								{this.renderList()}
							</div>
							<div>
								{this.renderNewTodo()}
							</div>
						</div>
						: undefined
				}
			</div>
		);
	}
	newTodo = async (todoBody) => {
		try {
			await createTodo(todoBody);
		} catch (e) {
			console.log(e);
		}
	}
	renderList() {
		return <TodoList list={this.state.list}
			handleDelete={this.handleDelete}
			handleUpdate={this.handleUpdate}
			setUpdateMode={this.setUpdateMode}
			updateValue={this.updateValue}
		>
		</TodoList>
	}
	renderNewTodo() {
		return <NewTodo newTodo={this.newTodo}></NewTodo>
	}
}

export default Todos;
