export default `
	query listDoctors($isFavouriteOnly:Boolean!){
			listDoctors(isFavouriteOnly:$isFavouriteOnly){
				items{
					name{
						firstName
						lastName
					}
					title
					speciality
					docId
					isFavourite
					clinic
					preferences{
						schedule{
							type
							startDate
							minsPerAppointment
							startHour
							startMinute
							endHour
							endMinute
						}
						billing{
							doctorRate
							clinicFee
							toBePaidAt
							services{
								title
								price
								bought
								currency
							}
						}
						fields{
							fieldName
							visible
							required
						}
					}
				}
			}
		}
	`