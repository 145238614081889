import React, { Component } from 'react';
import access from 'safe-access';

class DoctorList extends Component {
	handleChoice = (docId, title, firstName, lastName, speciality, step = 30, clinic = 'Clinic 1', doctorRate, clinicFee, toBePaidAt, services, fields, schedule) => {
		window.localStorage.setItem('currentDoctorId', docId);
		window.localStorage.setItem('currentDoctorTitle', title);
		window.localStorage.setItem('currentDoctorFirstName', firstName);
		window.localStorage.setItem('currentDoctorLastName', lastName);
		window.localStorage.setItem('currentDoctorSpeciality', speciality);
		window.localStorage.setItem('currentDoctorStep', step);
		window.localStorage.setItem('currentDoctorClinic', clinic);
		window.localStorage.setItem('currentDoctorRate', doctorRate);
		window.localStorage.setItem('currentDoctorClinicFee', clinicFee);
		window.localStorage.setItem('currentDoctorPayAt', toBePaidAt);
		window.localStorage.setItem('currentDoctorServices', JSON.stringify(services));
		window.localStorage.setItem('currentDoctorFields', JSON.stringify(fields));
		window.localStorage.setItem('currentDoctorSchedule', JSON.stringify(schedule));

		this.props.closeSidebar();
		this.props.showCalendarView();
		this.props.refresh();
	}
	render() {
		return (
			<div>
				{
					this.props.list ? this.props.list.map((doc, i) => {
						return (
							doc ?
								<div className={window.localStorage.getItem('currentDoctorId') && doc.docId === window.localStorage.getItem('currentDoctorId') ? "calendar__doctor-lists--item-active" : "calendar__doctor-lists--item-inactive"}
									onClick={() => this.handleChoice(doc.docId, doc.title, doc.name.firstName, doc.name.lastName, doc.speciality, access(doc, 'preferences.schedule[0].minsPerAppointment'), access(doc, 'clinic'), access(doc, 'preferences.billing.doctorRate'), access(doc, 'preferences.billing.clinicFee'), access(doc, 'preferences.billing.toBePaidAt'), access(doc, 'preferences.billing.services'), access(doc, 'preferences.fields'), access(doc, 'preferences.schedule'))
									} key={i} >
									<p className="calendar__doctor-lists--item-text">{doc.title ? doc.title : ""}.&nbsp;{doc.name && doc.name.lastName ? doc.name.lastName : ""},&nbsp; {doc.name && doc.name.firstName ? doc.name.firstName : ""}</p>
									<p className="calendar__doctor-lists--item-subtext">{doc.speciality ? doc.speciality : ""}</p>
									<br />
								</div>
								: <div key={i}></div>
						)
					})
						: null
				}
			</div>
		);
	}
}

export default DoctorList;
