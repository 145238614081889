import React from 'react'
import { withRouter } from 'react-router-dom'
import { FormGroup, FormControl, Panel } from 'react-bootstrap'
import { navigate } from 'react-big-calendar/lib/utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { searchAppointments, getAppointment } from './../../logic/calendar'
import moment from 'moment'

class CustomToolbarClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      searchBarVisible: false,
      searchQuery: '',
      showResults: false,
	  searchResult: [],
	  retrievePast: false
    }
  }
  componentWillMount = () => {
	const retrievePast = window.localStorage.getItem('currentRetrievePast');

	if (retrievePast) {
		this.setState({ retrievePast })
	}
}
  goToBack = () => {
    this.props.toolbar.onNavigate(navigate.PREVIOUS)
  }
  goToNext = () => {
    this.props.toolbar.onNavigate(navigate.NEXT)
  }
  goToCurrent = () => {
    this.props.toolbar.onNavigate(navigate.TODAY)
  }
  getLabel = () => {
    return <span className="rbc-toolbar-label">{this.props.toolbar.label}</span>
  }
  toggleRetrievePast = () => {
	let newRetrievePast = this.state.retrievePast
	
	if (newRetrievePast === "true" || newRetrievePast === true)	newRetrievePast = false;
	else newRetrievePast = true;

	window.localStorage.setItem('currentRetrievePast', newRetrievePast);
	this.setState({ retrievePast: newRetrievePast }, ()=>window.location.reload(false));
  }
  handleViewChange = view => {
    switch (view) {
      case 'day':
        this.props.toolbar.onView('day')
        break
      case 'week':
        this.props.toolbar.onView('week')
        break
      case 'month':
        this.props.toolbar.onView('month')
        break
      case 'agenda':
        this.props.toolbar.onView('agenda')
        break
      case 'billing':
        this.props.toolbar.onView('billing')
        break
      default:
        break
    }
  }
  toggleSearchBar = (e, val) => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({
      searchQuery: '',
      searchBarVisible: val,
      searchResult: [],
      showResults: false,
    })
  }
  startSearch = async e => {
    e.preventDefault()
    e.stopPropagation()

    this.setState({ searchBarVisible: true })
    const query = this.state.searchQuery

    try {
      const searchResult = await searchAppointments(
        window.localStorage.getItem('currentDoctorId'),
        query,
      )
      const appointments = []

      if (
        searchResult &&
        searchResult instanceof Array &&
        searchResult.length > 0
      ) {
        for (const r of searchResult) {
          console.log(r)
          appointments.push(r)
        }
        this.setState({ searchResult: appointments, showResults: true })
      }
    } catch (e) {
      console.log(e)
    }
  }
  goToAppointment = async (e, creationTimestamp) => {
    e.preventDefault()
    e.stopPropagation()

    try {
      const item = await getAppointment(
        window.localStorage.getItem('currentDoctorId'),
        creationTimestamp,
      )

      this.props.history.push({
        pathname: `/appointments/${creationTimestamp}`,
        state: { item },
      })
    } catch (e) {
      console.log(e)
    }
  }
  firstAvailability = () => {
    this.props.firstAvailability()
  }
  render = () => {
    return (
      <div className="toolbar-container">
        <div className="calendar__toolbar--nav-buttons">
          <button
            className="calendar__toolbar--today-button"
            onClick={this.goToCurrent}
          >
            TODAY
          </button>
          <button
            className="calendar__toolbar--back-button"
            onClick={this.goToBack}
          >
            &lt;
          </button>

          <button
            className="calendar__toolbar--next-button"
            onClick={this.goToNext}
          >
            &gt;
          </button>
          <label className="calendar__toolbar--label">{this.getLabel()}</label>
		  <button
			className="calendar__toolbar--today-button"
			style={{marginLeft:"5px", padding: "5px"}}
            onClick = {this.toggleRetrievePast}
          >
            {this.state.retrievePast === true || this.state.retrievePast === "true" ? "Do not show past appointments" : "Show past appointments"}
          </button>
        </div>

        <FormGroup className="calendar__toolbar--view">
          <div>
            {this.state.searchBarVisible === false ? (
              <button
                onClick={e => this.toggleSearchBar(e, true)}
                className="calendar__toolbar--search-button"
              >
                <FontAwesomeIcon icon="search" />
              </button>
            ) : (
              <div>
                <form onSubmit={this.startSearch} className="flex-row">
                  <FormControl
                    className="calendar__toolbar--search-bar"
                    id="search-bar"
                    autoFocus
                    autoComplete="off"
                    value={this.state.searchQuery}
                    placeholder="Search for patient details"
                    onChange={e =>
                      this.setState({ searchQuery: e.target.value })
                    }
                    type="text"
                  ></FormControl>
                  <input
                    type="submit"
                    className="calendar__toolbar--search-start-button"
                    value="SEARCH"
                  />
                  <FontAwesomeIcon
                    className="calendar__toolbar--search-cancel-button"
                    icon="times"
                    onClick={e => this.toggleSearchBar(e, false)}
                  ></FontAwesomeIcon>
                </form>
                <Panel
                  className="calendar__toolbar--search-result-container"
                  id="search-panel"
                  expanded={this.state.showResults}
                  onToggle={e => console.log(e)}
                >
                  <Panel.Collapse>
                    <Panel.Body>
                      {this.state.searchResult.map((result, i) => {
                        return (
                          <p
                            className="calendar__toolbar--search-result"
                            onClick={e =>
                              this.goToAppointment(e, result.creationTimestamp)
                            }
                            key={i}
                          >
                            {moment(result.fromTimestamp).format(
                              'DD MMM YYYY - HH:mm',
                            )}{' '}
                            - {moment(result.toTimestamp).format('HH:mm')} -{' '}
                            {result.patient.name.firstName}{' '}
                            {result.patient.name.lastName}{' '}
                            {result.patient.idCard
                              ? `- ${result.patient.idCard}`
                              : false}
                          </p>
                        )
                      })}
                    </Panel.Body>
                  </Panel.Collapse>
                </Panel>
              </div>
            )}
          </div>
          <button
            className="calendar__toolbar--first-availability-button"
            onClick={this.firstAvailability}
          >
            First Availability
          </button>
          <FormControl
            id="view"
            defaultValue={this.props.toolbar.view}
            className="calendar__toolbar--views noprint"
            componentClass="select"
            onChange={e => this.handleViewChange(e.target.value)}
          >
            <option value="month">Month</option>
            <option value="week">Week</option>
            <option value="day">Day</option>
            <option value="agenda">Agenda</option>
            <option value="billing">Billing</option>
          </FormControl>
        </FormGroup>
      </div>
    )
  }
}

export default withRouter(CustomToolbarClass)
