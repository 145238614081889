export default `
	mutation updateScheduleItem($docId:ID!,$creationTimestamp:AWSDateTime!,$item:ScheduleItemInput!) {
		updateScheduleItem(
			docId:$docId,
			creationTimestamp:$creationTimestamp,
      		item:$item
    ) {
    	docId
    	creationTimestamp
	}
}
`