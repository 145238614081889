import React, { Component } from 'react';
import { Col, Row, Grid, FormGroup, FormControl, ControlLabel, DropdownButton } from 'react-bootstrap';
import access from 'safe-access';
import map from './../../assets/images/map.png';
import moment from 'moment';
import { camel } from '../../libs/general';
import { getROItem } from './../../logic/roView';

class ROAppointmentDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {
				fromTimestamp: '',
				toTimestamp: '',
				patient: {
					name: {
						firstName: '',
						lastName: ''
					},
					email: '',
					mobile: {
						countryPrefixCode: '',
						mobileNumber: ''
					},
					landline: {
						countryPrefixCode: '',
						landlineNumber: ''
					},
					idCard: '',
					dob: ''
				},
				clinic: '',
			},
			error: ''
		};
	}
	componentWillMount = async () => {
		const { d, ct, i } = this.props;
		try {
			if (d && ct && i) {
				const item = await getROItem(d, ct, i);
				this.setState({ item });
			}

		} catch (e) {
			console.log(e);
		}
	}
	render() {
		return (
			<div>
				<div className="tiny-push"></div>
				<div style={{ marginBottom: 0, paddingBottom: 0 }} className="pages__modal--container">
					<div className="small-push"></div>
					<Grid>
						<Row>
							<Col xs={8} sm={8} md={8} lg={8}>{/*Patient Details*/}
								<Row>
									<Col xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='firstName'>First Name</ControlLabel>
											<FormControl
												id='firstName'
												disabled
												defaultValue={access(this, "state.item.patient.name.firstName") ? this.state.item.patient.name.firstName : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, name: { ...this.state.item.patient.name, firstName: e.target.value } } } })}
												type='text'>
											</FormControl>
										</FormGroup>
									</Col>
									<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='lastName'>Surname</ControlLabel>
											<FormControl
												id='lastName'
												disabled
												defaultValue={access(this, "state.item.patient.name.lastName") ? this.state.item.patient.name.lastName : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, name: { ...this.state.item.patient.name, lastName: e.target.value } } } })}
												type='text'>
											</FormControl>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='idCard'>ID Card</ControlLabel>
											<FormControl
												id='idCard'
												disabled
												defaultValue={access(this, "state.item.patient.idCard") ? this.state.item.patient.idCard : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, idCard: e.target.value } } })}
												type='text'>
											</FormControl>
										</FormGroup>
									</Col>
									<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='mobileNumber'>Mobile Number</ControlLabel>
											<FormControl
												id='mobileNumber'
												disabled
												defaultValue={access(this, "state.item.patient.mobile.mobileNumber") ? this.state.item.patient.mobile.mobileNumber : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, mobile: { ...this.state.item.patient.mobile, mobileNumber: e.target.value } } } })}
												type='int'>
											</FormControl>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='email'>Email</ControlLabel>
											<FormControl
												id='email'
												disabled
												defaultValue={access(this, "state.item.patient.email") ? this.state.item.patient.email : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, email: e.target.value } } })}
												type='email'>
											</FormControl>
										</FormGroup>
									</Col>
									<Col xsOffset={1} smOffset={1} mdOffset={1} lgOffset={1} xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='landlineNumber'>Landline Number</ControlLabel>
											<FormControl
												id='landlineNumber'
												disabled
												defaultValue={access(this, "state.item.patient.landline.landlineNumber") ? this.state.item.patient.landline.landlineNumber : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, landline: { ...this.state.item.patient.landline, landlineNumber: e.target.value } } } })}
												type='int'>
											</FormControl>
										</FormGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={5} sm={5} md={5} lg={5}>
										<FormGroup>
											<ControlLabel htmlFor='dob'>Date of Birth</ControlLabel>
											<FormControl
												id='dob'
												disabled
												defaultValue={access(this, "state.item.patient.dob") ? this.state.item.patient.dob : undefined}
												onChange={(e) => this.setState({ item: { ...this.state.item, patient: { ...this.state.item.patient, dob: e.target.value } } })}
												type='text'>
											</FormControl>
										</FormGroup>
									</Col>
								</Row>
								<hr></hr>
								<Row>
									<Col xs={7} sm={7} md={7} lg={7}>
										<a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Medicaid+Pharmacy/@35.8488505,14.5055409,17z">
											<img className="appointment__map--img" src={map} alt="Medicaid Pharmacy location on Google Maps" />
										</a>
									</Col>
									<Col className="appointment__address--container" xs={5} sm={5} md={5} lg={5}>
										<p id="business-name">Medicaid Pharmacy</p>
										<p id="business--address">62, Vjal it-Torri</p>
										<p id="business-town">Gudja</p>
										<a id="business-website" target="_blank" rel="noopener noreferrer" href="http://www.medicaidclinics.com">www.medicaidclinics.com</a><br />
										<a id="business-email" target="_blank" rel="noopener noreferrer" href="mailto:medicaidpharmacy@gmail.com">medicaidpharmacy@gmail.com</a>
										<p id="business-telephone">+356 21676294</p>
									</Col>
								</Row>
							</Col>
							<Col xs={4} sm={4} md={4} lg={4}>{/*Doc & Appointment Details*/}
								<div className="appointment__doctor-appointment--container" >
									<Row><p className="appointment__doctor--name">
										{`${camel(window.localStorage.getItem('currentDoctorTitle'))}. ${camel(window.localStorage.getItem('currentDoctorLastName'))}, ${camel(window.localStorage.getItem('currentDoctorFirstName'))}`}
									</p></Row>
									<Row><p className="appointment__doctor--speciality">{camel(window.localStorage.getItem('currentDoctorSpeciality'))}</p></Row>
									<div className="small-push"></div>
									<Row>
										<Col xs={6} sm={6} md={6} lg={6}>
											<ControlLabel htmlFor='fromTimestamp'>From</ControlLabel>
											<div>
												<p id="fromTimestampDate">{moment(this.state.item.fromTimestamp).format('DD MMM YYYY')}</p>
												<p id="fromTimestampTime">{moment(this.state.item.fromTimestamp).format('HH:mm')}</p>
											</div>
										</Col>
										<Col xs={6} sm={6} md={6} lg={6}>
											<ControlLabel htmlFor='toTimestamp'>To</ControlLabel>
											<div>
												<p id="toTimestampDate">{moment(this.state.item.toTimestamp).format('DD MMM YYYY')}</p>
												<p id="toTimestampTime">{moment(this.state.item.toTimestamp).format('HH:mm')}</p>
											</div>
										</Col>
									</Row>
									<div className="tiny-push"></div>
									<Row>
										<Col xs={6} sm={6} md={6} lg={6}>
											<FormGroup>
												<ControlLabel htmlFor="clinic">Clinic</ControlLabel>
												<FormControl
													componentClass="select"
													id='clinic'
													disabled
													required
													value={this.state.item.clinic}
													onChange={(e) => this.setState({ item: { ...this.state.item, clinic: e.target.value } })}>
													<option value=''>--</option>
													<option value='Clinic 1'>Clinic 1</option>
													<option value='Clinic 2'>Clinic 2</option>
													<option value='Clinic 3'>Clinic 3</option>
													<option value='Clinic 4'>Clinic 4</option>
												</FormControl>
											</FormGroup>
										</Col>
										<Col xs={6} sm={6} md={6} lg={6}>
										</Col>
									</Row>
								</div>
							</Col>
						</Row>
					</Grid>

					<Row>
						<div className="medium-push"></div>
						<div className="pages__form--error centered-text">
							{
								this.state.error && this.state.error instanceof Array ?
									this.state.error.map((error, i) => <p key={i}>{error.toString()}</p>)
									: <p>{this.state.error.message}</p>
							}
						</div>
					</Row>
				</div >
				<div className="medium-push"></div>
			</div >
		);
	}
}

export default ROAppointmentDetails;