export default `
	query listTodos{
			listTodos{
				items{
					todoId
					body
					createdBy
					createdOn
				}
			}
		}
	`