export default `
	query getReportDoctors($isFavouriteOnly:Boolean!){
			listDoctors(isFavouriteOnly:$isFavouriteOnly){
				items{
					name{
						firstName
						lastName
					}
					title
					docId
				}	
			}
		}
	`