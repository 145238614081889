import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { getRemindManuallyReport, getReportDoctors, getVisitingDoctorsReport } from './../../logic/reports';
import { sortBy } from './../../libs/general';

const today = moment().format('YYYY-MM-DD');
class Report extends Component {
	constructor(props) {
		super(props);
		this.state = {
			items: [],
			date: today,
			docs: null,
			report: "1"
		};
	}
	componentDidMount() {
		const { match } = this.props;
		if (match && match.params && match.params.id) {
			this.setState({ report: match.params.id }, () => this.handleDateChange({ target: today }))
		}
	}
	handleDateChange = async (e) => {
		const date = e.target.value;
		const mDate = moment(e.target.value).format('YYYY-MM-DD');
		try {
			let docs = null;
			let items = null;

			if (this.state.report === "1") items = await getRemindManuallyReport(mDate);
			else if (this.state.report === "2") items = await getVisitingDoctorsReport(mDate);

			if (!this.state.docs) docs = await getReportDoctors();
			else docs = this.state.docs;

			if (items && items instanceof Array) {
				for (const item of items) {
					const { patient } = item;
					const descriptionArr = [];

					if (patient) {
						if (patient.name && patient.name.firstName) descriptionArr.push(patient.name.firstName);
						if (patient.name && patient.name.lastName) descriptionArr.push(patient.name.lastName);
						if (patient.idCard) descriptionArr.push(patient.idCard);
						if (patient.mobile && patient.mobile.mobileNumber) descriptionArr.push(patient.mobile.mobileNumber);
						if (patient.landline && patient.landline.landlineNumber) descriptionArr.push(patient.landline.landlineNumber);
						if (patient.email) descriptionArr.push(patient.email);
						if (patient.dob) descriptionArr.push(patient.dob);
					}
					item.description = descriptionArr.map((t) => t.toString().substring(0, 1).toUpperCase() + t.toString().substring(1, t.toString().length)).join(' - ');

					if (item.docId && docs) {
						const doc = docs.find((d) => d.docId === item.docId);

						if (doc) {
							const docNameArr = [];

							if (doc.title) docNameArr.push(doc.title + '.');
							if (doc.name && doc.name.firstName) docNameArr.push(doc.name.firstName);
							if (doc.name && doc.name.lastName) docNameArr.push(doc.name.lastName);

							item.docName = docNameArr.map((t) => t.toString().substring(0, 1).toUpperCase() + t.toString().substring(1, t.toString().length)).join(' ');
						}
					}

					if (item && item.reminders) {
						if (item.reminders.email) {
							switch (item.reminders.email.lastStatus) {
								case "SENT":
									item.emailReminder = 'SENT';
									if (item.reminders.email.lastSentTimestamp)
										item.emailReminder += ` on ${moment(item.reminders.email.lastSentTimestamp).format('DD MMM YYYY HH:mm')}`;
									break;
								case "ERROR":
									item.emailReminder = 'ERROR OCCURED WHEN SENDING';
									break;
								default:
									item.emailReminder = 'NEVER SENT';
							}
						} else item.emailReminder = 'NEVER SENT';
						if (item.reminders.sms) {
							switch (item.reminders.sms.lastStatus) {
								case "SENT":
									item.smsReminder = 'SENT';
									if (item.reminders.sms.lastSentTimestamp)
										item.smsReminder += ` on ${moment(item.reminders.sms.lastSentTimestamp).format('DD MMM YYYY HH:mm')}`;
									break;
								case "ERROR":
									item.smsReminder = 'ERROR OCCURED WHEN SENDING';
									break;
								default:
									item.smsReminder = 'NEVER SENT';
							}
						} else item.smsReminder = 'NEVER SENT';
					} else {
						item.emailReminder = 'NEVER SENT';
						item.smsReminder = 'NEVER SENT';
					}
				};
				items.sort(sortBy('clinic', false, null,
					sortBy('fromTimestamp', false, null,
						sortBy('docId', false, null))));
				this.setState({ date, items });
			}

		} catch (error) {
			console.log(error);
		}

	}
	render() {
		return (
			<div>
				<div className="pages__back-button--container">
					<FontAwesomeIcon className="pages__back-button--icon" icon="chevron-left"></FontAwesomeIcon>
					<Link className="pages__back-button--text" to="/settings/reports">Back</Link>
				</div>
				<header className="pages__header--container centered-text">
					{
						this.state.report === "1" ?
							<h1>Remind Manually Report</h1>
							: <h1>Visiting Doctors Report</h1>
					}
				</header>
				<div className="reports__date">
					<span>Report date:&nbsp;&nbsp;</span>
					<input
						defaultValue={this.state.date}
						onChange={this.handleDateChange}
						type="date" />
				</div>
				<div>
					{
						this.state.items && this.state.items.length > 0 ?
							<table className="reports__container">
								<tbody>
									<tr>
										<th>Date</th>
										<th>Time</th>
										<th>Doctor</th>
										<th>Clinic</th>
										<th>Details</th>
										<th>SMS Reminder</th>
										<th>Email Reminder</th>
									</tr>
									{
										this.state.items.map((item, i) => {
											return (
												<tr	key={i}>
													<td>{moment(item.fromTimestamp).format('DD MMM YYYY')}</td>
													<td>{`${moment(item.fromTimestamp).format('HH:mm')} to ${moment(item.toTimestamp).format('HH:mm')}`}</td>
													<td>{item.docName}</td>
													<td>{item.clinic}</td>
													<td>{item.description}</td>
													<td>{item.smsReminder}</td>
													<td>{item.emailReminder}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
							: <div className="reports__nodata"><p>Report has no data</p></div>
					}
				</div>
				<div className="large-push"></div>
			</div>
		);
	}
}

export default Report;